import React from 'react';

const MarkdownHelp = ({ onClose }) => {
  const markdownExamples = [
    {
      title: 'Заголовки',
      examples: [
        { code: '# Заголовок 1', description: 'Заголовок первого уровня' },
        { code: '## Заголовок 2', description: 'Заголовок второго уровня' },
        { code: '### Заголовок 3', description: 'Заголовок третьего уровня' }
      ]
    },
    {
      title: 'Форматирование текста',
      examples: [
        { code: '**жирный текст**', description: 'Жирный текст' },
        { code: '*курсив*', description: 'Курсивный текст' },
        { code: '~~зачеркнутый~~', description: 'Зачеркнутый текст' }
      ]
    },
    {
      title: 'Списки',
      examples: [
        { code: '- элемент списка\n- второй элемент', description: 'Маркированный список' },
        { code: '1. первый элемент\n2. второй элемент', description: 'Нумерованный список' }
      ]
    },
    {
      title: 'Ссылки и изображения',
      examples: [
        { code: '[текст ссылки](URL)', description: 'Ссылка' },
        { code: '![alt текст](URL изображения)', description: 'Изображение' }
      ]
    },
    {
      title: 'Код',
      examples: [
        { code: '`строка кода`', description: 'Инлайн код' },
        { code: '```\nблок кода\n```', description: 'Блок кода' },
        { code: '```javascript\nconst x = 42;\n```', description: 'Код с подсветкой синтаксиса' }
      ]
    },
    {
      title: 'Цитаты и разделители',
      examples: [
        { code: '> цитата', description: 'Цитата' },
        { code: '---', description: 'Горизонтальная линия' }
      ]
    }
  ];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="markdown-help-modal">
      <div className="markdown-help-content">
        <div className="markdown-help-header">
          <h2>[?] Справка по Markdown</h2>
          <button onClick={onClose} className="close-button">[×]</button>
        </div>
        <div className="markdown-help-body">
          {markdownExamples.map((section, index) => (
            <div key={index} className="markdown-help-section">
              <h3>{section.title}</h3>
              <div className="markdown-examples">
                {section.examples.map((example, i) => (
                  <div key={i} className="markdown-example">
                    <code onClick={() => copyToClipboard(example.code)}>
                      {example.code}
                    </code>
                    <span className="example-description">{example.description}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarkdownHelp;
