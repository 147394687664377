import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/AdminNews.css';

function AdminNewsPage() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      setError('Необходима авторизация');
      return;
    }
    fetchNews();
  }, [token]);

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/news/admin/all`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setNews(response.data);
    } catch (err) {
      setError('Ошибка при загрузке новостей');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (slug) => {
    if (window.confirm('Вы уверены, что хотите удалить эту новость?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/news/${slug}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        fetchNews();
      } catch (err) {
        setError('Ошибка при удалении новости');
      }
    }
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="admin-news-page">
      <div className="page-header">
        <h1>[>] Управление новостями_</h1>
        {token && (
          <button 
            onClick={() => navigate('/admin/news/create')} 
            className="create-btn"
          >
            [+] Создать новость
          </button>
        )}
      </div>

      <div className="news-list">
        {news.map(item => (
          <div key={item.id} className="news-item">
            <div className="news-status">
              {item.is_pinned && <span className="pinned-badge">[📌]</span>}
              <span className={`status-badge ${item.status}`}>
                [{item.status === 'published' ? '✓' : '◯'}]
              </span>
            </div>
            
            <div className="news-content">
              <h3>{item.title}</h3>
              <div className="news-meta">
                <span className="news-date">
                  {new Date(item.created_at).toLocaleDateString('ru-RU')}
                </span>
                <span className="news-author">{item.author}</span>
              </div>
            </div>

            <div className="news-actions">
              <button onClick={() => navigate(`/admin/news/edit/${item.slug}`)} className="edit-btn">
                [✎]
              </button>
              <button onClick={() => handleDelete(item.slug)} className="delete-btn">
                [×]
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminNewsPage;