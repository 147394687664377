import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../components/CodeBlock';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import '../styles/NewsViewPage.css';

function NewsViewPage() {
  const { slug } = useParams();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [typingTitle, setTypingTitle] = useState('');
  const { isAdmin } = useAuth();
  
  useEffect(() => {
    if (news?.title) {
      let index = 0;
      const interval = setInterval(() => {
        if (index <= news.title.length) {
          setTypingTitle(news.title.slice(0, index));
          index++;
        } else {
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [news?.title]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await api.get(`/news/${slug}`);
        setNews(response.data);
      } catch (err) {
        setError('Новость не найдена');
      } finally {
        setLoading(false);
      }
    };
    fetchNews();
  }, [slug]);

  const renderMarkdown = (content) => {
    if (!content) return null;
    
    return (
      <ReactMarkdown
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <CodeBlock
                language={match[1]}
                value={String(children).replace(/\n$/, '')}
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!news) return null;

  return (
    <div className="terminal-container">
      <div className="terminal-window">
        <div className="terminal-header">
          <div className="terminal-controls">
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
          </div>
          <span className="terminal-title">[📰] News Article Viewer [v1.0]</span>
        </div>

        <div className="terminal-content">
          <Link to="/news" className="nv-back-link">[⮌] Back to News</Link>
          
          <article className="nv-article">
            <div className="nv-header">
              {news.is_pinned && <div className="nv-pinned-badge">[📌] Закреплено</div>}
              <h1 className="nv-typing-title">
                {typingTitle}<span className="nv-cursor">_</span>
              </h1>
              
              <div className="nv-meta">
                <span className="nv-date">
                  [🕒] Published: {new Date(news.created_at).toLocaleDateString('ru-RU')}
                </span>
                <span className="nv-access">
                  {news.is_private ? '[🔒] Private Access' : '[🌍] Public'}
                </span>
              </div>
            </div>

            <div className="nv-content">
              <div className="nv-short-description markdown-content">
                {renderMarkdown(news.short_description)}
              </div>

              <div className="nv-full-description markdown-content">
                {renderMarkdown(news.full_description)}
              </div>

              <div className="nv-main-content markdown-content">
                {renderMarkdown(news.content)}
              </div>
            </div>

            <div className="nv-footer">
              <Link to="/news" className="nv-back-link">[⮌] Back to News</Link>
              {news.is_private && !isAdmin && (
                <Link to="/dashboard" className="nv-unlock-link">
                  [⚙️] Unlock Full Access [>]
                </Link>
              )}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default NewsViewPage;
