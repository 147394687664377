// src/pages/Projects.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/common/TerminalWindow.css';
import '../styles/Projects.css';

const projects = [
  {
    id: 1,
    name: "NEAR Protocol",
    network: "Mainnet",
    status: "Active",
    roi: "+40%",
    icon: "[◎]",
    logo: "/images/near-logo.svg",
    description: "High-stake mainnet node for transaction validation and block production."
  },
  {
    id: 2,
    name: "Solana",
    network: "Mainnet",
    status: "Active",
    roi: "+35%",
    icon: "[◎]",
    logo: "/images/solana-logo.svg",
    description: "High-performance mainnet node for secure, scalable transaction validation."
  },
  {
    id: 3,
    name: "Ritual",
    network: "Testnet",
    status: "Monitoring",
    roi: "+20%",
    icon: "[⧫]",
    logo: "/images/ritual-logo.svg",
    description: "Testnet node setup for early-stage validation and reward accumulation."
  },
  {
    id: 4,
    name: "Waku",
    network: "Testnet",
    status: "Active",
    roi: "+25%",
    icon: "[○]",
    logo: "/images/waku-logo.svg",
    description: "Light node deployment focused on decentralized messaging and privacy protocols."
  },
  {
    id: 5,
    name: "Private Projects",
    network: "Confidential",
    status: "Active",
    roi: "Undisclosed",
    icon: "[⟁]",
    logo: "/images/private-logo.svg",
    description: "Multiple high-stake and specialized nodes maintained exclusively for private community members.",
    isConfidential: true
  }
];

function Projects() {
  const [activeProject, setActiveProject] = useState(null);
  const [glitchEffect, setGlitchEffect] = useState(false);

  const renderPrivateProjectCard = (project) => (
    <div
      key={project.id}
      className={`project-card private-card ${activeProject === project.id ? 'active' : ''} ${glitchEffect ? 'glitch' : ''}`}
      onMouseEnter={() => {
        setActiveProject(project.id);
        // Запускаем эффект глитча каждые 2 секунды
        const interval = setInterval(() => setGlitchEffect(prev => !prev), 2000);
        return () => clearInterval(interval);
      }}
      onMouseLeave={() => {
        setActiveProject(null);
        setGlitchEffect(false);
      }}
    >
      <div className="project-header private">
        <div className="classified-stamp">CLASSIFIED</div>
        <div className="project-logo-container">
          <div className="encrypted-icon">[⟁]</div>
        </div>
        <h2 className="project-name">
          <span className="encrypted-text">Private Projects</span>
          <span className="access-level">[LEVEL 5 CLEARANCE REQUIRED]</span>
        </h2>
      </div>
      <div className="project-details encrypted">
        <div className="detail-item">
          <span className="detail-label">› Status:</span>
          <span className="detail-value status-restricted">RESTRICTED ACCESS</span>
        </div>
        <div className="detail-item">
          <span className="detail-label">› Network:</span>
          <span className="detail-value">[REDACTED]</span>
        </div>
        <div className="detail-item">
          <span className="detail-label">› ROI:</span>
          <span className="detail-value classified">████████</span>
        </div>
      </div>
      <div className="encrypted-message">
        <p>Encrypted data available for authorized members only</p>
        <button className="access-btn">
          <span className="btn-text">Request Access</span>
          <span className="btn-icon">_</span>
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Projects - xNod3 - Active Blockchain Nodes & Deployments</title>
        <meta name="description" content="Explore xNod3's active blockchain projects, including testnet and mainnet nodes with exclusive performance insights and uptime metrics." />
        <meta name="keywords" content="xNod3 projects, blockchain nodes, testnet nodes, mainnet nodes, node deployment, active blockchain projects" />
        <link rel="canonical" href="https://xnod3.com/projects" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Projects - xNod3 - Active Blockchain Nodes & Deployments" />
        <meta property="og:description" content="View xNod3's active projects in blockchain, featuring testnet and mainnet nodes with exclusive performance and uptime insights." />
        <meta property="og:image" content="/images/projects-overview.png" />
        <meta property="og:url" content="https://xnod3.com/projects" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Projects - xNod3 - Active Blockchain Nodes & Deployments" />
        <meta name="twitter:description" content="Explore xNod3's blockchain projects with testnet and mainnet nodes, including performance and uptime data." />
        <meta name="twitter:image" content="/images/projects-overview.png" />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": "xNod3 Blockchain Projects",
            "description": "Active blockchain node deployments and projects",
            "mainEntity": {
              "@type": "ItemList",
              "itemListElement": projects.map((project, index) => ({
                "@type": "Product",
                "position": index + 1,
                "name": project.name,
                "description": project.description,
                "offers": {
                  "@type": "Offer",
                  "availability": project.status === "Active" ? 
                    "https://schema.org/InStock" : 
                    "https://schema.org/OutOfStock"
                }
              }))
            }
          })}
        </script>
      </Helmet>
      <div className="projects-container">
        <div className="projects-header">
          <img src="/images/xnod3-logo.png" alt="xNod3 logo" className="xnod3-logo" />
          <h1 className="projects-title">Active Node Projects <span className="cursor">_</span></h1>
        </div>
        <div className="projects-grid">
          {projects.map(project => 
            project.isConfidential ? 
              renderPrivateProjectCard(project) : 
              <div
                key={project.id}
                className={`project-card ${activeProject === project.id ? 'active' : ''}`}
                onMouseEnter={() => setActiveProject(project.id)}
                onMouseLeave={() => setActiveProject(null)}
              >
                <div className="project-header">
                  <div className="project-logo-container">
                    <img 
                      src={project.logo} 
                      alt={`${project.name} logo`} 
                      className="project-logo"
                      onError={(e) => {
                        e.target.style.display = 'none';
                        e.target.nextElementSibling.style.display = 'block';
                      }}
                    />
                    <span className="project-icon fallback">{project.icon}</span>
                  </div>
                  <h2 className="project-name">{project.name}</h2>
                </div>
                <div className="project-details">
                  <div className="detail-item">
                    <span className="detail-label">› Network:</span>
                    <span className="detail-value">{project.network}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">› Status:</span>
                    <span className={`detail-value status-${project.status.toLowerCase()}`}>
                      {project.status}
                    </span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">› ROI:</span>
                    <span className="detail-value roi">{project.roi}</span>
                  </div>
                </div>
                <p className="project-description">{project.description}</p>
              </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Projects;
