import React from 'react';

const ImageLibrary = ({ images, loading, onSelect, onClose }) => {
  const copyMarkdown = async (url, publicId) => {
    const markdown = `![${publicId}](${url})`;
    await navigator.clipboard.writeText(markdown);
    // Можно добавить уведомление о копировании
  };

  return (
    <div className="image-library-modal">
      <div className="modal-content">
        <h3>[🖼] Image Library</h3>
        <div className="image-grid">
          {loading ? (
            <div className="loading">Загрузка изображений...</div>
          ) : (
            images.map(img => (
              <div key={img.public_id} className="image-item">
                <img src={img.url} alt={img.public_id} />
                <div className="image-item-actions">
                  <button 
                    onClick={() => onSelect(img.url, img.public_id)}
                    className="insert-button"
                  >
                    [+] Вставить
                  </button>
                  <button 
                    onClick={() => copyMarkdown(img.url, img.public_id)}
                    className="copy-button"
                  >
                    [📋] Копировать markdown
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        <button onClick={onClose} className="close-button">
          [×] Закрыть
        </button>
      </div>
    </div>
  );
};

export default ImageLibrary;
