import React, { useState, useEffect, useCallback } from 'react';
import '../styles/CommandLineLoader.css';

const TYPING_SPEED = 30;
const LOADER_STEPS = [
  { text: 'Initializing xNod3 Protocol...', delay: 200 },
  { text: 'Connecting to nodes...', delay: 20 },
  { text: 'Loading scripts...', delay: 150 },
  { text: 'Verifying...', delay: 50 },
  { text: 'Access Granted. Welcome to xNod3.', delay: 300, isLast: true }
];

const CommandLineLoader = ({ onLoadingComplete }) => {
  const [steps, setSteps] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [glitchEffect, setGlitchEffect] = useState(false);

  // Эффект печати текста
  useEffect(() => {
    if (currentStepIndex >= LOADER_STEPS.length) return;

    const currentStep = LOADER_STEPS[currentStepIndex];
    if (!currentStep) return;

    if (currentText.length < currentStep.text.length) {
      const timer = setTimeout(() => {
        setCurrentText(prev => currentStep.text.slice(0, prev.length + 1));
        setProgress(prev => Math.min(prev + (100 / (LOADER_STEPS.length * 15)), 100));
      }, TYPING_SPEED);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        if (currentStep.isLast) {
          setFadeOut(true);
          setTimeout(() => onLoadingComplete(), 1000);
        } else {
          setSteps(prev => [...prev, currentText]);
          setCurrentText('');
          setCurrentStepIndex(prev => prev + 1);
        }
      }, currentStep.delay);
      return () => clearTimeout(timer);
    }
  }, [currentText, currentStepIndex, onLoadingComplete]);

  return (
    <div className="loader-container">
      <div className="loader-terminal">
        <div className="loader-header">
          <div className="loader-controls">
            <div className="loader-control red"></div>
            <div className="loader-control yellow"></div>
            <div className="loader-control green"></div>
          </div>
          <div className="loader-title">xNod3_Terminal v1.0</div>
          <div className="loader-time">{new Date().toLocaleTimeString()}</div>
        </div>
        <div className="loader-content">
          <div className="matrix-bg"></div>
          {steps.map((step, index) => (
            <div key={index} className="command-line">
              <span className="prompt">[xNod3]$</span> {step}
            </div>
          ))}
          {currentText && (
            <div className="command-line typing">
              <span className="prompt">[xNod3]$</span> {currentText}
              <span className="cursor">█</span>
            </div>
          )}
          <div className="progress-line">
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="progress-text">{Math.min(Math.floor(progress), 100)}%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommandLineLoader; 