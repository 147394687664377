import React, { useState, useEffect, useCallback } from 'react';
import MDEditor from '@uiw/react-md-editor';
import api from '../services/api';
import '../styles/AdminTelegramPage.css';

function AdminTelegramPage() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    publishNow: false,
    thread_id: null
  });

  const fetchPosts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/telegram-posts');
      setPosts(response.data);
    } catch (err) {
      setError('Ошибка при загрузке постов');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleEdit = (post) => {
    setEditingPost(post);
    setFormData({
      title: post.title,
      content: post.content,
      publishNow: false,
      thread_id: post.thread_id
    });
    setShowForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (editingPost) {
        if (editingPost.status === 'published') {
          await api.put(`/telegram-posts/${editingPost.id}/published`, {
            title: formData.title,
            content: formData.content
          });
        } else {
          await api.put(`/telegram-posts/${editingPost.id}`, {
            title: formData.title,
            content: formData.content
          });
        }
      } else {
        await api.post('/telegram-posts', formData);
      }
      setFormData({
        title: '',
        content: '',
        publishNow: false,
        thread_id: null
      });
      setShowForm(false);
      setEditingPost(null);
      fetchPosts();
    } catch (err) {
      setError(editingPost ? 'Ошибка при обновлении поста' : 'Ошибка при создании поста');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (postId, newStatus) => {
    try {
      await api.patch(`/telegram-posts/${postId}/status`, { status: newStatus });
      fetchPosts();
    } catch (err) {
      setError('Ошибка при обновлении статуса');
    }
  };

  const handlePublish = async (postId) => {
    try {
      await api.post(`/telegram-posts/${postId}/publish`);
      fetchPosts();
    } catch (err) {
      setError('Ошибка при публикации поста');
    }
  };

  const handleDelete = async (postId) => {
    if (window.confirm('Вы уверены, что хотите удалить тот пост?')) {
      try {
        await api.delete(`/telegram-posts/${postId}`);
        fetchPosts();
      } catch (err) {
        setError('Ошибка при удалении поста');
      }
    }
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="admin-telegram">
      <div className="page-header">
        <h1>[📢] Telegram Посты_</h1>
        <button onClick={() => {
          setShowForm(!showForm);
          setEditingPost(null);
          setFormData({ title: '', content: '', publishNow: false, thread_id: null });
        }} className="create-btn">
          {showForm ? '[×] Отменить' : '[+] Создать пост'}
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="post-form">
          <h2>{editingPost ? '[✎] Редактирование поста' : '[+] Новый пост'}</h2>
          <div className="form-group">
            <label>[>] Заголовок_</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>[>] ID темы (опционально)_</label>
            <input
              type="number"
              value={formData.thread_id || ''}
              onChange={(e) => setFormData({
                ...formData, 
                thread_id: e.target.value ? parseInt(e.target.value) : null
              })}
              min="1"
              placeholder="Оставьте пустым для публикации без темы"
            />
          </div>

          <div className="form-group">
            <label>[>] Текст сообщения_</label>
            <MDEditor
              value={formData.content}
              onChange={(value) => setFormData({...formData, content: value || ''})}
              preview="edit"
              height={300}
              className="telegram-editor__md-editor"
            />
          </div>

          <div className="form-group checkbox">
            <label>
              <input
                type="checkbox"
                checked={formData.publishNow}
                onChange={(e) => setFormData({...formData, publishNow: e.target.checked})}
              />
              [>] Опубликовать сразу_
            </label>
          </div>

          <button type="submit" className="submit-btn">
            {editingPost ? '[✓] Сохранить изменения' : '[+] Создать пост'}
          </button>
        </form>
      )}

      <div className="posts-list">
        {posts.map(post => (
          <div key={post.id} className="post-item">
            <div className="post-header">
              <h3>{post.title}</h3>
              <div className="post-actions">
                {post.status === 'draft' ? (
                  <>
                    <button onClick={() => handleEdit(post)} className="edit-btn">
                      [✎] Редактировать
                    </button>
                    <button onClick={() => handlePublish(post.id)} className="publish-btn">
                      [↗] Опубликовать в Telegram
                    </button>
                    <button onClick={() => handleDelete(post.id)} className="delete-btn">
                      [×] Удалить
                    </button>
                  </>
                ) : (
                  <button onClick={() => handleEdit(post)} className="edit-btn">
                    [✎] Редактировать в Telegram
                  </button>
                )}
              </div>
            </div>
            <div className="post-content">
              <MDEditor.Markdown source={post.content} />
            </div>
            <div className="post-meta">
              <span>Статус: {post.status === 'published' ? 'Опубликован' : 'Черновик'}</span>
              <span>Создан: {new Date(post.created_at).toLocaleString('ru-RU')}</span>
              {post.published_at && (
                <span>Опубликован: {new Date(post.published_at).toLocaleString('ru-RU')}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminTelegramPage;