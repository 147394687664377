import React from 'react';
import InteractiveEditor from './InteractiveEditor';

const SectionEditor = ({ sections, onChange, getSectionIcon, getSectionTitle, getSectionPlaceholder }) => {
  const handlePaste = (e, sectionKey) => {
    const items = e.clipboardData.items;
    const imageItem = Array.from(items).find(item => item.type.startsWith('image'));
    
    if (imageItem) {
      e.preventDefault();
      window.dispatchEvent(new CustomEvent('handlePaste', {
        detail: { event: e, sectionKey }
      }));
    }
  };

  return (
    <div className="guide-sections">
      {Object.keys(sections).map(key => (
        <div key={key} className="section-container">
          <h3>
            <span className="section-icon">{getSectionIcon(key)}</span>
            {getSectionTitle(key)}
          </h3>
          <InteractiveEditor
            value={sections[key]}
            onChange={(value) => onChange(key, value)}
            placeholder={getSectionPlaceholder(key)}
            onPaste={(e) => handlePaste(e, key)}
          />
        </div>
      ))}
    </div>
  );
};

export default SectionEditor;