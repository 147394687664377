import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/privateAccess';
import LanguageToggle from '../components/LanguageToggle';
import '../styles/PrivateAccess.css';
import { Link } from 'react-router-dom';
import { useSubscriptionPrice } from '../hooks/useSubscriptionPrice';

function PrivateAccess() {
  const { language } = useLanguage();
  const t = translations[language] || translations['en'];
  const { price, loading } = useSubscriptionPrice();
  
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [currentLogIndex, setCurrentLogIndex] = useState(0);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentUpdateIndex, setCurrentUpdateIndex] = useState(0);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('monthly');

  useEffect(() => {
    setCurrentLogIndex(0);
    
    const timer = setInterval(() => {
      setCurrentLogIndex(prev => {
        if (prev < t.installationLogs.length) {
          return prev + 1;
        }
        clearInterval(timer);
        return prev;
      });
    }, 500);

    return () => clearInterval(timer);
  }, [t.installationLogs.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentMessageIndex < t.supportMessages.length) {
        setCurrentMessageIndex(prev => prev + 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [currentMessageIndex, t.supportMessages.length]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft') {
        setSelectedPlan(prev => {
          if (prev === 'twelveMonths') return 'sixMonths';
          if (prev === 'sixMonths') return 'monthly';
          return prev;
        });
      } else if (e.key === 'ArrowRight') {
        setSelectedPlan(prev => {
          if (prev === 'monthly') return 'sixMonths';
          if (prev === 'sixMonths') return 'twelveMonths';
          return prev;
        });
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const calculatePrice = (plan) => {
    if (loading) return { total: '...', perMonth: '...' };
    
    const monthlyPrice = price.amount;
    switch (plan) {
      case 'sixMonths':
        const sixMonthTotal = monthlyPrice * 6 * 0.9; // 10% скидка
        return {
          total: sixMonthTotal.toFixed(2),
          perMonth: (sixMonthTotal / 6).toFixed(2)
        };
      case 'twelveMonths':
        const yearTotal = monthlyPrice * 12 * 0.83; // 17% скидка
        return {
          total: yearTotal.toFixed(2),
          perMonth: (yearTotal / 12).toFixed(2)
        };
      default:
        return {
          total: monthlyPrice,
          perMonth: monthlyPrice
        };
    }
  };

  return (
    <>
      <Helmet>
        <title>{t.meta.title}</title>
        <meta name="description" content={t.meta.description} />
        <meta name="keywords" content={t.meta.keywords} />
      </Helmet>

      <div className="private-access-container">
        <LanguageToggle />
        
        <div className="private-header">
          <h1 className="typing-text">{t.title}</h1>
          <p className="header-description">{t.headerDescription}</p>
        </div>

        <section className="pricing-section">
          <h2 className="section-title">{t.pricing.sectionTitle}</h2>
          <p className="section-description">{t.pricing.description}</p>
                    
          <div className="plan-selector">
            <button 
              className={`plan-option ${selectedPlan === 'monthly' ? 'active' : ''}`}
              onClick={() => setSelectedPlan('monthly')}
            >
              <span className="plan-icon">[M]</span>
              {t.pricing.planSelector.monthly}
            </button>
            
            <button 
              className={`plan-option ${selectedPlan === 'sixMonths' ? 'active' : ''}`}
              onClick={() => setSelectedPlan('sixMonths')}
            >
              <span className="plan-icon">[6]</span>
              6M
              <span className="savings">-10%</span>
            </button>
            
            <button 
              className={`plan-option ${selectedPlan === 'twelveMonths' ? 'active' : ''}`}
              onClick={() => setSelectedPlan('twelveMonths')}
            >
              <span className="plan-icon">[Y]</span>
              1Y
              <span className="savings">-17%</span>
            </button>
          </div>
          <div className="keyboard-hint">
            [←/→]
          </div>

          <div className="pricing-card premium">
            <div className="price-header">
              <div className="price-badge">[MEMBERSHIP]</div>
              <span className="price-icon">[₿]</span>
              <div className="price-details">
                <h3 className="price-amount">
                  {loading ? '...' : (
                    selectedPlan === 'monthly' 
                      ? `$${price.amount}`
                      : `$${calculatePrice(selectedPlan).total}`
                  )}
                </h3>
                <span className="price-per-month">
                  {selectedPlan !== 'monthly' && `($${calculatePrice(selectedPlan).perMonth}/month)`}
                </span>
                {selectedPlan !== 'monthly' && (
                  <span className="save-badge">
                    SAVE {selectedPlan === 'sixMonths' ? '10' : '17'}% [🔥]
                  </span>
                )}
              </div>
            </div>

            <ul className="price-benefits">
              {t.pricing.benefits.map((benefit, index) => (
                <li key={index}>
                  <span className="bullet">[>]</span>
                  {benefit}
                </li>
              ))}
            </ul>

            <div className="price-footer">
              <Link 
                to="/dashboard" 
                className="subscribe-btn"
                onClick={() => {
                  localStorage.setItem('selectedPlan', selectedPlan);
                }}
              >
                <span className="btn-text">{t.pricing.button.text}</span>
                <span className="btn-icon">{t.pricing.button.icon}</span>
              </Link>
              <p className="subscription-note">
                <span className="secure-badge">{t.pricing.footer.secureBadge}</span>
                <br />
                {t.pricing.footer.note}
              </p>
            </div>
          </div>
        </section>

        <section className="features-section">
          {t.features.map(feature => (
            <div key={feature.id} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
              <button className="feature-action">{feature.action}</button>
            </div>
          ))}
        </section>

        <section className="script-preview">
          <h2 className="preview-title">{t.interface.preview.scriptTitle}</h2>
          <p className="preview-description">{t.interface.preview.scriptDescription}</p>

          <div className="private-terminal-container">
            <div className="private-terminal-header">
              <span className="private-terminal-dot"></span>
              <span className="private-terminal-dot"></span>
              <span className="private-terminal-dot"></span>
              <span className="private-terminal-title">{t.interface.terminal.title}</span>
            </div>
            
            <div className="private-command-section">
              <div className="private-command-box">
                <code className="private-command-text">{t.interface.preview.scriptCommand}</code>
                <button 
                  className="copy-button"
                  onClick={() => {
                    navigator.clipboard.writeText(t.interface.preview.scriptCommand);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  }}
                >
                  {copied ? t.interface.copyButton.copied : t.interface.copyButton.default}
                </button>
              </div>
            </div>

            <div className="private-terminal-logs">
              {t.installationLogs.slice(0, currentLogIndex).map((log, index) => (
                <div key={index} className={`log-line ${log.type}`}>
                  {log.text}
                </div>
              ))}
              <span className="terminal-cursor">{t.interface.terminal.cursor}</span>
            </div>
          </div>
        </section>

        <section className="support-preview">
          <h2 className="support-title">{t.interface.preview.supportTitle}</h2>
          <p className="support-description">{t.interface.preview.supportDescription}</p>

          <div className="chat-window">
            <div className="chat-messages">
              {t.supportMessages.slice(0, currentMessageIndex).map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  <div className="message-content">
                    <div className="message-text">
                      {message.text}
                      {message.command && (
                        <div className="message-command">
                          <code>{message.command}</code>
                          <button 
                            className="copy-button-small"
                            onClick={() => {
                              navigator.clipboard.writeText(message.command);
                              setCopied(true);
                              setTimeout(() => setCopied(false), 2000);
                            }}
                          >
                            {copied ? t.interface.copyButton.copied : t.interface.copyButton.default}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="content-wrapper">
          <section className="updates-section">
            <h2 className="updates-title">Latest Updates</h2>
            <div className="updates-container">
              {t.updates.map((update, index) => (
                <div key={index} className={`update-card ${update.type} ${update.isNew ? 'new' : ''}`}>
                  <div className="update-header">
                    <span className="update-date">{update.date}</span>
                    {update.isNew && <span className="new-badge">NEW</span>}
                  </div>
                  <h3 className="update-title">{update.title}</h3>
                  <p className="update-description">{update.description}</p>
                  {update.eventDate && (
                    <div className="event-details">
                      <span className="event-icon">[📅]</span>
                      <span className="event-date">{update.eventDate}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>

        <section className="faq-section">
          <h2 className="section-title">{t.pricing.sectionTitle}</h2>
          <div className="faq-container">
            {t.faqData.map((faq) => (
              <div 
                key={faq.id} 
                className={`faq-item ${expandedFaq === faq.id ? 'expanded' : ''}`}
                onClick={() => setExpandedFaq(expandedFaq === faq.id ? null : faq.id)}
              >
                <div className="faq-question">
                  <span className="question-icon">[?]</span>
                  {faq.question}
                  <span className="expand-icon">{expandedFaq === faq.id ? '[-]' : '[+]'}</span>
                </div>
                <div className="faq-answer">
                  {faq.answer.map((line, index) => (
                    <p key={index} className="answer-line">
                      {line.includes('subscription') || line.includes('подписк') ? (
                        <>
                          {line}{' '}
                          <Link to="/dashboard" className="dashboard-link">
                            [Go to Dashboard →]
                          </Link>
                        </>
                      ) : (
                        line
                      )}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default PrivateAccess; 