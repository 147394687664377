import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/common/TerminalWindow.css';
import '../styles/Enterprise.css';
import { Link } from 'react-router-dom';

const services = [
  {
    id: 'deployment',
    title: 'Node Deployment',
    icon: '[>]',
    description: 'Secure, scalable, and fast deployment of blockchain nodes across testnets and mainnets, fully managed by our expert team.',
    stats: 'Deployment Time: ~2h',
    pricing: {
      testnet: 'Service provided for a percentage of future potential rewards + server costs (monthly or as needed).',
      mainnet: 'Monthly payment plan or customized billing options available.'
    }
  },
  {
    id: 'monitoring',
    title: '24/7 Monitoring & Support',
    icon: '[*]',
    description: 'Continuous performance monitoring and real-time troubleshooting to ensure zero downtime and optimal operations.',
    stats: 'Uptime: 99.99%',
    pricing: {
      testnet: 'Percentage of potential rewards + server costs.',
      mainnet: 'Monthly or customized payment plan for uninterrupted support.'
    }
  },
  {
    id: 'optimization',
    title: 'Profit Optimization & ROI Tracking',
    icon: '[%]',
    description: 'Maximize returns with our data-driven approach to performance and revenue tracking.',
    stats: 'Avg ROI: +35%',
    pricing: {
      testnet: 'Based on future rewards and server costs.',
      mainnet: 'Monthly payment based on infrastructure scale.'
    }
  }
];

const projects = [
  {
    name: 'Chasm Network',
    network: 'Testnet',
    status: 'Active',
    uptime: '99.99%',
    roi: '+2000%',
    description: 'Large-scale node deployment with automated management',
    metrics: {
      nodesPerServer: '10-100',
      serverCost: '$10/month',
      mintingCost: '$0.01/node',
      rewards: '$150-$200/node'
    },
    testimonial: 'The team at xNod3 provided seamless node deployment for Chasm Network, resulting in exceptional returns on our investment.',
    confidential: false
  },
  {
    name: 'Solana TdS',
    network: 'Testnet',
    status: 'Completed',
    uptime: '99.99%',
    roi: '+2000%',
    description: 'Tour de SOL testnet participation with high-performance validation',
    metrics: {
      monthlyRewards: '$500 in SOL',
      monthlyCost: '$24',
      totalEarnings2021: '~$15,000',
      totalEarningsAll: '~$2,300,000'
    },
    testimonial: 'Participated in Tour de SOL with exceptional uptime and performance metrics, contributing to network stability and earning significant rewards.',
    confidential: false
  }
];

const steps = [
  {
    id: 1,
    title: 'Assessment',
    description: 'Detailed analysis of your infrastructure needs.',
    icon: '>'
  },
  {
    id: 2,
    title: 'Deployment',
    description: 'Customized node deployment, optimized for performance.',
    icon: '//'
  },
  {
    id: 3,
    title: 'Monitoring',
    description: 'Real-time monitoring and maintenance for seamless operations.',
    icon: '*'
  },
  {
    id: 4,
    title: 'Optimization',
    description: 'Continuous performance enhancements for higher profitability.',
    icon: '%'
  }
];

const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div 
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && <div className="tooltip-content">{content}</div>}
    </div>
  );
};

function EnterprisePage() {
  const [activeService, setActiveService] = useState(null);
  const [typedText, setTypedText] = useState('');
  const fullText = "Enterprise Solutions for Blockchain Infrastructure";
  
  const getStepTooltip = (stepTitle) => {
    const tooltips = {
      'Assessment': '> Analyzing infrastructure requirements and performance goals',
      'Deployment': '> Setting up nodes with optimized configurations',
      'Monitoring': '> 24/7 system monitoring and performance tracking',
      'Optimization': '> Continuous improvements based on performance metrics'
    };
    return tooltips[stepTitle] || '';
  };

  useEffect(() => {
    let currentText = '';
    let currentIndex = 0;

    const typeText = () => {
      if (currentIndex < fullText.length) {
        currentText += fullText[currentIndex];
        setTypedText(currentText);
        currentIndex++;
        setTimeout(typeText, 50);
      }
    };

    typeText();
  }, []);

  return (
    <>
      <Helmet>
        <title>Enterprise Solutions - Professional Blockchain Node Deployment | xNod3</title>
        <meta name="description" content="Explore xNod3's Enterprise Solutions for blockchain node deployment, custom infrastructure, and 24/7 technical support. Tailored services to meet large-scale blockchain needs." />
        <meta name="keywords" content="enterprise solutions, blockchain node deployment, custom infrastructure, technical support, xNod3, blockchain services" />
        <link rel="canonical" href="https://xnod3.com/enterprise" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Enterprise Solutions - Professional Blockchain Node Deployment | xNod3" />
        <meta property="og:description" content="Professional blockchain node deployment and custom infrastructure solutions from xNod3." />
        <meta property="og:image" content="/images/enterprise-solutions.png" />
        <meta property="og:url" content="https://xnod3.com/enterprise" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Enterprise Solutions - Professional Blockchain Node Deployment | xNod3" />
        <meta name="twitter:description" content="Discover xNod3's tailored blockchain infrastructure services for enterprise needs." />
        <meta name="twitter:image" content="/images/enterprise-solutions.png" />
      </Helmet>

      <div className="enterprise-container">
        <div className="enterprise-header">
          <h1 className="typing-text">{typedText}<span className="cursor">_</span></h1>
          <p className="header-description">
            Unlock the full potential of blockchain with our enterprise-grade node deployment 
            and management services. Designed for stability, optimized for profitability.
          </p>
        </div>

        <section className="services-section">
          {services.map(service => (
            <div
              key={service.id}
              className={`service-card ${activeService === service.id ? 'active' : ''}`}
              onMouseEnter={() => setActiveService(service.id)}
              onMouseLeave={() => setActiveService(null)}
            >
              <div className="service-icon">{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <div className="service-stats">{service.stats}</div>
            </div>
          ))}
        </section>

        <section className="projects-section">
          <h2 className="section-title">Featured Projects</h2>
          <div className="projects-grid">
            {projects.map(project => (
              <div key={project.name} className="project-card">
                <div className="project-header">
                  <h3><span className="project-icon">[◎]</span> {project.name}</h3>
                  <div className="project-network">{project.network}</div>
                </div>
                <p className="project-description">{project.description}</p>
                <div className="project-metrics">
                  <h4 className="metrics-title">Performance Metrics:</h4>
                  <div className="metrics-grid">
                    {project.metrics && Object.entries(project.metrics).map(([key, value]) => {
                      const getIcon = (key) => {
                        if (key.toLowerCase().includes('roi')) return '📊';
                        if (key.toLowerCase().includes('cost')) return '💵';
                        if (key.toLowerCase().includes('earnings') || key.toLowerCase().includes('rewards')) return '📈';
                        return '▸';
                      };
                      
                      return (
                        <div key={key} className="metric-item">
                          <span className="metric-icon">{getIcon(key)}</span>
                          <span className="metric-label">{key.split(/(?=[A-Z])/).join(' ')}:</span>
                          <span className="metric-value">{value}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="project-testimonial">
                  <div className="testimonial-content">
                    <span className="quote-icon">"</span>
                    {project.testimonial}
                    <span className="quote-icon">"</span>
                  </div>
                  <span className="cursor">_</span>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="roi-stats-section">
          <div className="roi-stats-container">
            <h2 className="section-title">Performance Metrics</h2>
            <div className="roi-stats-grid">
              <div className="roi-stat-card">
                <div className="stat-icon">[%]</div>
                <div className="stat-value">+2000%</div>
                <div className="stat-label">Chasm Network ROI</div>
              </div>
              <div className="roi-stat-card">
                <div className="stat-icon">[⟁]</div>
                <div className="stat-value">$2.3M+</div>
                <div className="stat-label">Total Solana Earnings</div>
              </div>
              <div className="roi-stat-card">
                <div className="stat-icon">[∑]</div>
                <div className="stat-value">99.99%</div>
                <div className="stat-label">Average Uptime</div>
              </div>
            </div>
          </div>
        </section>

        <section className="steps-section">
          <h2 className="section-title">Deployment Process<span className="cursor">_</span></h2>
          <p className="section-description">
            Our step-by-step approach to ensure a seamless and optimized node deployment.
          </p>
          
          <div className="steps-container">
            {steps.map((step, index) => (
              <div key={step.id} className="step-card" data-step={`0${index + 1}`}>
                <div className="step-header">
                  <span className="step-icon">{step.icon}</span>
                  <span className="step-number">Step {index + 1}</span>
                </div>
                
                <div className="step-content">
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                  <span className="step-cursor">_</span>
                </div>
                
                <div className="step-tooltip">
                  {getStepTooltip(step.title)}
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="pricing-section">
          <h2 className="section-title">Pricing & Payment Terms</h2>
          <p className="pricing-description">
            At xNod3, we offer flexible payment options tailored to the unique requirements of testnet and mainnet environments. 
            Our goal is to ensure that our clients only pay for the resources they need while maximizing their potential rewards.
          </p>

          <div className="pricing-models">
            <div className="pricing-model-card">
              <h3 className="model-title">
                <span className="model-icon">[$]</span> 
                Testnet Payment Structure
              </h3>
              <div className="model-content">
                <Tooltip content="Our reward-sharing model ensures alignment of interests">
                  <p className="highlighted-text">20% of rewards</p>
                </Tooltip>
                <p className="model-description">For Testnet, we operate on a model where the cost consists of:</p>
                <ul className="model-features">
                  <li>> Percentage of Future Rewards: Fixed share of earned rewards</li>
                  <li>> Monthly Server Costs: Pay only for actual resources used</li>
                </ul>
                <div className="example-box">
                  <h4 className="example-title">> Example Calculation</h4>
                  <p>Server Cost: $100/month</p>
                  <p>Reward Share: 20% of earned rewards</p>
                  <p className="example-note">If testnet generates $500/month:</p>
                  <p className="calculation">$100 (server) + $100 (20% of $500) = $200 total</p>
                </div>
              </div>
            </div>

            <div className="pricing-model-card">
              <h3 className="model-title">[M] Mainnet Payment Structure</h3>
              <div className="model-content">
                <p className="model-description">Designed for stability and predictability:</p>
                <ul className="model-features">
                  <li>> Fixed Monthly Fee: Includes deployment, monitoring & support</li>
                  <li>> Flexible Payment Options: Monthly or quarterly plans</li>
                </ul>
                <div className="example-box">
                  <h4 className="example-title">> Available Plans</h4>
                  <p>Basic Plan: $500/month</p>
                  <p>Premium Plan: $1000/month</p>
                  <p className="plan-note">Includes priority support and ROI optimization</p>
                </div>
              </div>
            </div>
          </div>

          <div className="custom-options">
            <h3 className="options-title">[C] Custom Payment Options</h3>
            <p className="options-description">
              Every project is unique. We offer combined models and tailored plans for specific needs.
            </p>
            <button className="contact-btn">
              Contact Us for Custom Pricing [>]
              <span className="cursor">_</span>
            </button>
          </div>
        </section>

        <section className="contact-section">
          <div className="terminal-window">
            <div className="terminal-header">
              <span className="terminal-title">// Start Your Journey</span>
            </div>
            <div className="terminal-content">
              <div className="typing-effect">
                > Ready to deploy your infrastructure?_
              </div>
              <p className="terminal-text">
                Contact us to discuss your project requirements and get started with professional node deployment.
              </p>
              <Link to="/contact" className="terminal-button">
                <span className="button-text">Deploy With Us [>]</span>
                <span className="button-cursor">_</span>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default EnterprisePage; 