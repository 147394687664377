import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import '../styles/AdminGuidesPage.css';

function AdminGuidesPage() {
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [notificationType, setNotificationType] = useState('success');

  useEffect(() => {
    fetchGuides();
  }, []);

  const fetchGuides = async () => {
    try {
      const response = await api.get('/guides');
      const guidesWithProjects = await Promise.all(
        response.data.map(async guide => {
          if (guide.project_id) {
            const projectResponse = await api.get(`/projects/${guide.project_id}/node-version`);
            return {
              ...guide,
              projectInfo: projectResponse.data
            };
          }
          return guide;
        })
      );
      setGuides(guidesWithProjects);
    } catch (err) {
      setError('Error loading guides');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteGuide = async (slug) => {
    if (window.confirm('Вы уверены, что хотите удалить этот гайд?')) {
      try {
        await api.delete(`/guides/${slug}`);
        setGuides(guides.filter(guide => guide.slug !== slug));
        setNotification('Гайд успешно удален');
        setNotificationType('success');
      } catch (error) {
        console.error('Error deleting guide:', error);
        setNotification(error.response?.data?.error || 'Ошибка при удалении гайда');
        setNotificationType('error');
      }
    }
  };

  if (loading) return <div className="admin-guides-loading">[>] Loading...</div>;
  if (error) return <div className="admin-guides-error">[!] {error}</div>;

  return (
    <div className="admin-guides-page">
      <div className="admin-guides-header">
        <h1 className="admin-guides-title">[>] Guides Management_</h1>
        <Link to="/admin/guides/new" className="admin-create-guide-btn">
          [+] Create New Guide
        </Link>
      </div>

      <div className="admin-guides-list">
        {guides.map(guide => (
          <div key={guide.id} className="admin-guide-item">
            <div className="admin-guide-info">
              <h3>{guide.title}</h3>
              <p className="admin-guide-meta">
                <span className="category">[{guide.category}]</span>
                <span className="status">{guide.status}</span>
              </p>
            </div>
            <div className="admin-guide-actions">
              <Link 
                to={`/admin/guides/edit/${guide.slug}`} 
                className="admin-edit-btn"
              >
                [✎] Edit
              </Link>
              <button 
                onClick={() => handleDeleteGuide(guide.slug)}
                className="admin-delete-btn"
              >
                [×] Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminGuidesPage;
