import React, { useState, useEffect } from 'react';
import '../styles/AboutPage.css';
import BackgroundMatrix from '../components/BackgroundMatrix';

const AboutPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  
  const highlightedWords = {
    'hidden knowledge': 'Ancient wisdom transformed into modern security',
    'secure connections': 'Encrypted pathways to the future',
    'privacy': 'A core value of our philosophy',
    'decentralized frontier': 'The new digital landscape'
  };

  const team = [
    { 
      id: 'grisha',
      symbol: '[◎]',
      codeName: 'grisha_nodes',
      role: 'Lead Technical Specialist',
      description: 'grisha_nodes is the backbone of xNod3\'s technical infrastructure, the keeper of node stability, and the go-to for support.',
      command: './run support --grisha_nodes [>]'
    },
    { 
      id: 'fred',
      symbol: '[◎]',
      codeName: 'fred',
      role: 'Co-Founder',
      description: 'fred is the visionary behind xNod3, blending ancient principles with cutting-edge technology to create a truly unique ecosystem.',
      command: './initiate mission --fred [>]'
    }
  ];

  useEffect(() => {
    setIsVisible(true);
    const timer = setInterval(() => {
      setTextIndex(prev => prev < 3 ? prev + 1 : prev);
    }, 800);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="about-container">
      <BackgroundMatrix />
      
      <div className={`about-content ${isVisible ? 'about-section' : ''}`}>
        <div className="about-header">
          <h1 className="about-title">About xNod3<span className="cursor">_</span></h1>
          <p className="about-subtitle">The minds behind xNod3 - a cryptic alliance of blockchain experts</p>
        </div>

        <div className="about-text-section">
          <p className={`about-text ${textIndex >= 0 ? 'visible' : ''}`}>
            xNod3 emerged from the shadows of the blockchain realm, founded by visionaries who saw the need for a more secure and private approach to node deployment.
          </p>
          
          <p className={`about-text ${textIndex >= 1 ? 'visible' : ''}`}>
            Our mission is to provide enterprise-grade node infrastructure while maintaining the highest standards of <span className="highlight" title="A core value of our philosophy">privacy</span> and security.
          </p>
          
          <p className={`about-text ${textIndex >= 2 ? 'visible' : ''}`}>
            We operate at the intersection of <span className="highlight" title="Ancient wisdom transformed into modern security">hidden knowledge</span> and modern technology, creating <span className="highlight" title="Encrypted pathways to the future">secure connections</span> in the <span className="highlight" title="The new digital landscape">decentralized frontier</span>.
          </p>
        </div>

        <div className="team-section">
          <h2 className="team-title">The Team<span className="cursor">_</span></h2>
          <div className="team-list">
            {team.map(member => (
              <div key={member.id} className="team-member" data-aos="fade-up">
                <div className="member-header">
                  <span className="member-symbol">{member.symbol}</span>
                  <div className="member-info">
                    <span className="member-name">{member.codeName}</span>
                    <span className="member-role">{member.role}</span>
                  </div>
                </div>
                <p className="member-description">{member.description}</p>
                <div className="member-command">
                  <span className="command-prompt">$</span>
                  <span className="command-text">{member.command}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage; 