import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import api from '../services/api';
import ImageLibrary from '../components/guides/GuideEditor/ImageLibrary';
import '../styles/NewsEditor.css';

function NewsEditorPage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(slug);
  
  const [formData, setFormData] = useState({
    title: '',
    shortDescription: '',
    fullDescription: '',
    content: '',
    slug: '',
    status: 'draft',
    isPinned: false,
    isPrivate: false
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (isEditing) {
      fetchNews();
    }
  }, [slug]);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        if (!localStorage.getItem('token')) {
          setError('Необходима авторизация');
          navigate('/admin/news');
          return;
        }

        const response = await api.get('/auth/me');
        
        if (!response.data.isAdmin) {
          setError('Недостаточно прав');
          navigate('/admin/news');
        }
      } catch (err) {
        setError('Ошибка проверки прав');
        navigate('/admin/news');
      }
    };

    checkAdmin();
  }, []);

  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/news/${slug}`);
      
      setFormData({
        title: response.data.title,
        shortDescription: response.data.short_description || '',
        fullDescription: response.data.full_description || '',
        content: response.data.content || '',
        slug: response.data.slug,
        status: response.data.status,
        isPinned: response.data.is_pinned,
        isPrivate: response.data.is_private
      });
    } catch (err) {
      console.error('Error fetching news:', err);
      setError(err.response?.data?.error || 'Ошибка при загрузке новости');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const dataToSend = {
        ...formData,
        slug: formData.slug || formData.title.toLowerCase()
          .replace(/[^a-zа-я0-9\s-]/g, '')
          .replace(/\s+/g, '-')
      };

      if (isEditing) {
        await api.put(`/news/${slug}`, dataToSend);
      } else {
        await api.post('/news', dataToSend);
      }
      navigate('/admin/news');
    } catch (err) {
      console.error('Error saving news:', err);
      setError(err.response?.data?.error || 'Ошибка при сохранении новости');
    } finally {
      setLoading(false);
    }
  };

  const fetchImages = async () => {
    try {
      setImageLoading(true);
      const response = await api.get('/upload/images');
      setImages(response.data.images || []);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError(err.response?.data?.error || 'Ошибка при загрузке списка изображений');
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      setError('Поддерживаются только изображения в форматах JPEG, PNG и GIF');
      return;
    }

    try {
      setImageLoading(true);
      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const imageMarkdown = `![${response.data.public_id}](${response.data.url})`;
      await navigator.clipboard.writeText(imageMarkdown);
      
      fetchImages();
      
      setError(null);
    } catch (err) {
      console.error('Error uploading image:', err);
      setError(err.response?.data?.error || 'Ошибка при загрузке изображения');
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageSelect = async (url, publicId) => {
    const markdown = `![${publicId}](${url})`;
    await navigator.clipboard.writeText(markdown);
    setShowImageLibrary(false);
  };

  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    const imageItem = Array.from(items).find(item => item.type.startsWith('image'));
    
    if (!imageItem) return;
    
    e.preventDefault();
    
    try {
      setImageLoading(true);
      const file = imageItem.getAsFile();
      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const imageMarkdown = `![${response.data.public_id}](${response.data.url})`;
      await navigator.clipboard.writeText(imageMarkdown);
      
      fetchImages();
    } catch (err) {
      console.error('Error uploading pasted image:', err);
      setError(err.response?.data?.error || 'Ошибка при загрузке изображения');
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div className="news-editor">
      <h1 className="news-editor__title">
        {isEditing ? 'Редактирование новости' : 'Создание новости'}
      </h1>
      
      {loading ? (
        <div className="news-editor__loading">Загрузка...</div>
      ) : (
        <form onSubmit={handleSubmit} className="news-editor__form">
          <div className="news-editor__form-group">
            <label className="news-editor__label">[>] Заголовок_</label>
            <input
              className="news-editor__input"
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
            />
          </div>

          <div className="news-editor__form-group">
            <label className="news-editor__label">[>] URL (slug)_</label>
            <input
              type="text"
              value={formData.slug}
              onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
              placeholder="url-novosti"
              className="news-editor__input"
            />
          </div>

          <div className="news-editor__form-group">
            <label className="news-editor__label">[>] Краткое описание_</label>
            <MDEditor
              value={formData.shortDescription}
              onChange={(value) => setFormData({ ...formData, shortDescription: value || '' })}
              preview="edit"
              height={150}
              className="news-editor__md-editor"
            />
          </div>

          <div className="news-editor__form-group">
            <label className="news-editor__label">[>] Полное описание_</label>
            <MDEditor
              value={formData.fullDescription}
              onChange={(value) => setFormData({ ...formData, fullDescription: value || '' })}
              preview="edit"
              height={300}
              className="news-editor__md-editor"
            />
          </div>

          <div className="news-editor__form-group">
            <label className="news-editor__label">[>] Контент_</label>
            <MDEditor
              value={formData.content}
              onChange={(value) => setFormData({ ...formData, content: value || '' })}
              preview="edit"
              height={500}
              className="news-editor__md-editor"
            />
          </div>

          <div className="news-editor__options">
            <div className="news-editor__checkbox-group">
              <label className="news-editor__checkbox-label">
                <input
                  type="checkbox"
                  checked={formData.isPinned}
                  onChange={(e) => setFormData({ ...formData, isPinned: e.target.checked })}
                  className="news-editor__checkbox"
                />
                Закрепить новость
              </label>
            </div>

            <div className="news-editor__select-group">
              <label className="news-editor__label">[>] Статус_</label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                className="news-editor__select"
              >
                <option value="draft">Черновик</option>
                <option value="published">Опубликовано</option>
              </select>
            </div>
          </div>

          <div className="news-editor__form-group">
            <div className="news-editor__checkbox-group">
              <input
                type="checkbox"
                id="isPrivate"
                checked={formData.isPrivate}
                onChange={(e) => setFormData({ ...formData, isPrivate: e.target.checked })}
                className="news-editor__checkbox"
              />
              <label htmlFor="isPrivate" className="news-editor__checkbox-label">
                [🔒] Private Access
              </label>
            </div>
          </div>

          {error && (
            <div className="news-editor__error">
              <span className="news-editor__error-icon">[!]</span>
              <span className="news-editor__error-text">{error}</span>
            </div>
          )}

          <div className="news-editor__actions">
            <button 
              type="button" 
              onClick={() => navigate('/admin/news')} 
              className="news-editor__btn news-editor__btn--cancel"
            >
              [←] Отмена
            </button>
            <button 
              type="submit" 
              className="news-editor__btn news-editor__btn--save"
            >
              [✓] Сохранить
            </button>
          </div>
        </form>
      )}

      <div className="news-editor__image-tools">
        <label className="news-editor__upload-btn">
          [📷] Загрузить изображение
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </label>
        <button
          type="button"
          onClick={() => setShowImageLibrary(true)}
          className="news-editor__library-btn"
        >
          [🖼] Библиотека изображений
        </button>
      </div>

      {showImageLibrary && (
        <ImageLibrary
          images={images || []}
          loading={imageLoading}
          onSelect={handleImageSelect}
          onClose={() => setShowImageLibrary(false)}
        />
      )}
    </div>
  );
}

export default NewsEditorPage;