// src/pages/Contact.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/Contact.css';
import '../styles/common/TerminalWindow.css';
import axios from 'axios';

function Contact() {
  const [formData, setFormData] = useState({
    nickname: '',
    email: '',
    telegram: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleFocus = (field) => {
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleBlur = (field) => {
    if (field === 'email' && formData.email) {
      if (!validateEmail(formData.email)) {
        setErrors(prev => ({ ...prev, email: 'Invalid email format' }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    // Валидация
    const newErrors = {};
    if (!formData.nickname) newErrors.nickname = 'Name/Nickname is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!validateEmail(formData.email)) newErrors.email = 'Invalid email format';
    if (!formData.telegram) newErrors.telegram = 'Telegram username is required';
    if (!formData.message) newErrors.message = 'Message is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact/send`,
        formData
      );
      
      if (response.data.success) {
        setStatus('Message sent successfully!');
        setFormData({ nickname: '', email: '', telegram: '', message: '' });
      } else {
        setStatus('Error sending message. Please try again.');
      }
    } catch (error) {
      setStatus('Error sending message. Please try again.');
    }
    
    setIsSubmitting(false);
    setTimeout(() => setStatus(''), 5000);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - xNod3 - Get in Touch</title>
        <meta name="description" content="Contact xNod3 for information on blockchain node deployment, enterprise solutions, or private access membership. Connect with our team today." />
        <meta name="keywords" content="contact xNod3, blockchain support, node deployment inquiry, private access, enterprise solutions" />
        <link rel="canonical" href="https://xnod3.com/contact" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Contact Us - xNod3 - Get in Touch" />
        <meta property="og:description" content="Connect with xNod3 for inquiries on our exclusive blockchain solutions and support services." />
        <meta property="og:image" content="/images/contact.png" />
        <meta property="og:url" content="https://xnod3.com/contact" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - xNod3 - Get in Touch" />
        <meta name="twitter:description" content="Connect with xNod3 for inquiries on our exclusive blockchain solutions and support services." />
        <meta name="twitter:image" content="/images/contact.png" />
      </Helmet>

      <div className="contact-container">
        <h1 className="contact-title">Get in Touch <span className="cursor">_</span></h1>
        <p className="contact-description">
          Connect with the xNod3 team for inquiries, support, or partnership opportunities.
        </p>

        <div className="contact-methods">
          <div className="contact-card">
            <h2 className="method-title">
              <span className="method-icon">[✉]</span> Email
            </h2>
            <a href="mailto:support@xnod3.com" className="contact-link">
              › support@xnod3.com
            </a>
          </div>

          <div className="contact-card">
            <h2 className="method-title">
              <span className="method-icon">[⚡]</span> Telegram
            </h2>
            <div className="telegram-links">
              <a href="https://t.me/grisha_nodes" className="contact-link">
                › Main Support: @grisha_nodes
              </a>
              <a href="https://t.me/xNod3" className="contact-link">
                › Public Channel: @xNod3
              </a>
            </div>
          </div>
        </div>

        <div className="terminal-window">
          <div className="terminal-header">
            <div className="terminal-controls">
              <div className="terminal-control"></div>
              <div className="terminal-control"></div>
              <div className="terminal-control"></div>
            </div>
            <span className="terminal-title">xNod3_Contact [v1.0]</span>
            <span className="terminal-status">[SECURE]</span>
          </div>
          <div className="terminal-content">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="input-group">
                <span className="terminal-prompt">› Enter your Name / Nickname:</span>
                <input
                  type="text"
                  name="nickname"
                  value={formData.nickname}
                  onChange={(e) => setFormData({...formData, nickname: e.target.value})}
                  className="terminal-input"
                  required
                  placeholder="_"
                />
              </div>
              
              <div className="input-group">
                <span className="terminal-prompt">› Enter your Telegram Username:</span>
                <input
                  type="text"
                  name="telegram"
                  value={formData.telegram}
                  onChange={(e) => setFormData({...formData, telegram: e.target.value})}
                  className="terminal-input"
                  required
                  placeholder="@username"
                />
              </div>
              
              <div className="input-group">
                <span className="terminal-prompt">› Enter your email:</span>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  className="terminal-input"
                  required
                  placeholder="_"
                />
              </div>
              
              <div className="input-group">
                <span className="terminal-prompt">› Enter your message:</span>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={(e) => setFormData({...formData, message: e.target.value})}
                  className="terminal-input"
                  required
                  placeholder="_"
                  rows="4"
                ></textarea>
              </div>
              
              <button type="submit" className="submit-btn" disabled={isSubmitting}>
                <span className="btn-text">{isSubmitting ? 'Sending...' : 'Send Message'}</span>
                <span className="btn-icon">_</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
