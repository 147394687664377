import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { useSubscriptionPrice } from '../hooks/useSubscriptionPrice';
import '../styles/PaymentModal.css';

const PaymentModal = ({ onClose, onSubmit }) => {
  const [months, setMonths] = useState(12);
  const { price, loading, error: priceError } = useSubscriptionPrice();

  const getDiscountedPrice = (basePrice, months, discount) => {
    const total = basePrice * months;
    return discount > 0 ? total * (1 - discount / 100) : total;
  };

  const plans = {
    1: { 
      label: 'INIT_1M', 
      discount: 0,
      period: '1 month'
    },
    3: { 
      label: 'INIT_3M', 
      discount: 5,
      period: '3 months'
    },
    6: { 
      label: 'INIT_6M', 
      discount: 10,
      period: '6 months'
    },
    12: { 
      label: 'INIT_12M', 
      discount: 17,
      period: '12 months'
    }
  };

  if (loading) return <div className="paymentModal-loading">[...] Loading price data</div>;
  if (priceError) return <div className="paymentModal-error">[!] Error: {priceError}</div>;

  const basePrice = Number(price.amount);
  const selectedPlan = plans[months];
  const finalPrice = getDiscountedPrice(basePrice, months, selectedPlan.discount).toFixed(2);

  return (
    <div className="paymentModal-wrapper">
      <div className="paymentModal-container">
        <div className="paymentModal-header">
          <h2>[>] Private Access<span className="paymentModal-cursor">_</span></h2>
          <button className="paymentModal-closeBtn" onClick={onClose}>[x]</button>
        </div>
        
        <div className="paymentModal-content">
          <div className="paymentModal-planSelector">
            {Object.entries(plans).map(([period, plan]) => {
              const planPrice = getDiscountedPrice(basePrice, Number(period), plan.discount).toFixed(2);
              return (
                <button
                  key={period}
                  className={`paymentModal-planOption ${months === Number(period) ? 'paymentModal-planOption--active' : ''}`}
                  onClick={() => setMonths(Number(period))}
                >
                  <div className="paymentModal-planDuration">{plan.label}</div>
                  <div className="paymentModal-planPrice">${planPrice}</div>
                  {plan.discount > 0 && (
                    <div className="paymentModal-planDiscount">OPTIMIZE_{plan.discount}%</div>
                  )}
                </button>
              );
            })}
          </div>

          <div className="paymentModal-summary">
            <div className="paymentModal-summaryHeader">[SYS] Access Configuration</div>
            <div className="paymentModal-summaryContent">
              <div className="paymentModal-summaryRow">
                <span>INIT_TYPE</span>
                <span>{selectedPlan.label}</span>
              </div>
              <div className="paymentModal-summaryRow">
                <span>TIME_FRAME</span>
                <span>{selectedPlan.period}</span>
              </div>
              <div className="paymentModal-summaryRow">
                <span>BASE_RATE</span>
                <span>${basePrice}/month</span>
              </div>
              <div className="paymentModal-summaryRow">
                <span>OPTIMIZATION</span>
                <span>{selectedPlan.discount > 0 ? `${selectedPlan.discount}%` : 'NONE'}</span>
              </div>
              <div className="paymentModal-summaryRow paymentModal-summaryRow--total">
                <span>REQUIRED_AMOUNT</span>
                <span>${finalPrice}</span>
              </div>
            </div>
          </div>

          <div className="paymentModal-payment">
            <div className="paymentModal-paymentMethods">
              <span className="paymentModal-cryptoIcon">₿</span>
              <span className="paymentModal-cryptoIcon">Ξ</span>
              <span className="paymentModal-cryptoIcon">₮</span>
            </div>
            <div className="paymentModal-paymentNote">[ Crypto transactions only | Instant activation ]</div>
            <button className="paymentModal-paymentHelp">[?] Payment protocol assistance</button>
          </div>

          <div className="paymentModal-cancelNote">
            [i] Access can be terminated at any time. Full control included.
          </div>

          <a href="#" className="paymentModal-learnMore">
            [>] Read Private Access protocol documentation
          </a>
        </div>

        <button className="paymentModal-activateBtn" onClick={() => onSubmit(months)}>
          Initialize Private Access [${finalPrice}]
        </button>
      </div>
    </div>
  );
};

export default PaymentModal;
