import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function AdminLink() {
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <Link to="/admin/guides" className="admin-link">
      [⚙] Admin Panel
    </Link>
  );
}

export default AdminLink;
