import React, { useState, useEffect } from 'react';
import api from '../services/api';
import './TelegramAccess.css';

const TelegramAccess = ({ subscription }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inviteLink, setInviteLink] = useState('');
  const [chatStatus, setChatStatus] = useState(null);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    checkChatStatus();
  }, []);

  const checkChatStatus = async () => {
    try {
      setIsChecking(true);
      const response = await api.get('/telegram/status');
      setChatStatus(response.data.isMember ? 'member' : 'not_member');
    } catch (error) {
      console.error('Failed to check chat status:', error);
      setError('Failed to check chat status');
    } finally {
      setIsChecking(false);
    }
  };

  const handleGetAccess = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.post('/telegram/invite');
      setInviteLink(response.data.inviteLink);
      setTimeout(checkChatStatus, 5000);
    } catch (error) {
      if (error.response?.status === 429) {
        setInviteLink(error.response.data.inviteLink);
      }
      setError('Failed to get invite link');
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (isChecking) {
      return (
        <div className="terminal-block">
          <div className="terminal-line">
            <span className="terminal-icon">[⟳]</span>
            <span>CHECKING CHAT STATUS...</span>
          </div>
        </div>
      );
    }

    if (subscription?.status !== 'active') {
      return (
        <div className="terminal-block">
          <div className="terminal-line">
            <span className="terminal-icon">[🔒]</span>
            <span>PREMIUM SUBSCRIPTION REQUIRED</span>
          </div>
        </div>
      );
    }

    if (chatStatus === 'member') {
      return (
        <div className="terminal-block">
          <div className="terminal-line success">
            <span className="terminal-icon">[✓]</span>
            <span>CONNECTED TO PRIVATE CHAT</span>
          </div>
        </div>
      );
    }

    return (
      <div className="terminal-block">
        {inviteLink ? (
          <>
            <div className="terminal-line">
              <span className="terminal-icon">[i]</span>
              <span>YOUR INVITE LINK (EXPIRES IN 1 HOUR):</span>
            </div>
            <div className="terminal-action">
              <a href={inviteLink} target="_blank" rel="noopener noreferrer" className="terminal-button">
                JOIN CHAT [→]
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="terminal-line">
              <span className="terminal-icon">[>]</span>
              <span>GENERATE CHAT ACCESS LINK</span>
            </div>
            <div className="terminal-action">
              <button 
                onClick={handleGetAccess} 
                disabled={loading}
                className="terminal-button"
              >
                {loading ? 'GENERATING...' : 'GET LINK [↗]'}
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="terminal-section">
      <div className="terminal-header">
        <span className="terminal-prompt">[>]</span>
        <span className="terminal-title">TELEGRAM ACCESS</span>
      </div>
      {renderContent()}
      {error && (
        <div className="terminal-error">
          <span className="terminal-icon">[!]</span>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default TelegramAccess;
