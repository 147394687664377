import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const defaultBaseURL = isDevelopment ? 'http://localhost:5055/api' : '/api';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || defaultBaseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Добавляем перехватчик запросов
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (refreshToken) {
    config.headers['x-refresh-token'] = refreshToken;
  }
  
  return config;
});

// Добавляем перехватчик ответов
api.interceptors.response.use(
  response => {
    const newToken = response.headers['x-new-token'];
    if (newToken) {
      localStorage.setItem('token', newToken);
    }
    return response;
  },
  error => {
    // Список публичных маршрутов
    const publicRoutes = [
      '/guides',
      '/news',
      '/wiki/articles'
    ];

    // Проверяем, является ли текущий URL публичным маршрутом
    const isPublicRoute = publicRoutes.some(route => 
      error.config.url.includes(route)
    );

    // Делаем редирект только если это не публичный маршрут
    if ((error.response?.status === 401 || error.response?.status === 403) && !isPublicRoute) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default api;