import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-logo">[>] XNOD3_</div>
          <div className="footer-copyright">
            © {currentYear} [<span className="blink">|</span>] All rights reserved
          </div>
        </div>
        
        <div className="footer-section">
          <div className="footer-links">
            <Link to="/about" className="footer-link">[>] About</Link>
            <Link to="/contact" className="footer-link">[>] Contact</Link>
            <Link to="/guides" className="footer-link">[>] Knowledge</Link>
          </div>
        </div>

        <div className="footer-section">
          <div className="footer-social">
            <a href="https://t.me/xnod3" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <span className="social-icon">[tg]</span> Telegram
            </a>
            <a href="https://twitter.com/xnod3com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <span className="social-icon">[tw]</span> Twitter
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
