import React, { useState, useRef, useEffect } from 'react';
import './InteractiveEditor.css';

const InteractiveEditor = ({ value, onChange, placeholder, onPaste }) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef(null);
  const toolbarRef = useRef(null);

  const handleSelectionChange = (e) => {
    const textarea = editorRef.current;
    if (!textarea) return;

    const selection = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );

    if (selection.length > 0) {
      // Получаем позицию курсора относительно textarea
      const cursorPosition = textarea.selectionStart;
      
      // Находим строку и позицию в строке
      const lines = textarea.value.substr(0, cursorPosition).split('\n');
      const currentLineNumber = lines.length - 1;
      const currentLine = lines[currentLineNumber];
      
      // Вычисляем координаты
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
      const { top, left } = textarea.getBoundingClientRect();
      
      setToolbarPosition({
        top: top + (currentLineNumber * lineHeight) - 40,
        left: left + (currentLine.length * 8)
      });
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  };

  const applyFormat = (format) => {
    const textarea = editorRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);
    
    let formattedText = '';
    switch (format) {
      case 'bold':
        formattedText = `**${selectedText}**`;
        break;
      case 'italic':
        formattedText = `*${selectedText}*`;
        break;
      case 'code':
        formattedText = `\`${selectedText}\``;
        break;
      case 'link':
        formattedText = `[${selectedText}](url)`;
        break;
      case 'h1':
        formattedText = `# ${selectedText}`;
        break;
      case 'h2':
        formattedText = `## ${selectedText}`;
        break;
      case 'h3':
        formattedText = `### ${selectedText}`;
        break;
      case 'quote':
        formattedText = `> ${selectedText}`;
        break;
      case 'orderedList':
        // Находим последний номер списка перед выделением
        const textBefore = textarea.value.substring(0, start);
        const lastNumber = textBefore.match(/(\d+)\.\s*[^\n]*$/);
        const startNumber = lastNumber ? parseInt(lastNumber[1]) + 1 : 1;
        
        formattedText = selectedText.split('\n')
          .map((line, i) => `${startNumber + i}. ${line.trim()}`)
          .join('\n');
        break;
    }
    
    const newContent = textarea.value.substring(0, start) + formattedText + textarea.value.substring(end);
    onChange(newContent);
    
    setShowToolbar(false);
  };

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  return (
    <div className="interactive-editor-container">
      <textarea
        ref={editorRef}
        className="interactive-editor-textarea"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onMouseUp={handleSelectionChange}
        onKeyUp={handleSelectionChange}
        onPaste={onPaste}
      />
      
      {showToolbar && (
        <div 
          ref={toolbarRef}
          className="formatting-toolbar"
          style={{
            top: `${toolbarPosition.top}px`,
            left: `${toolbarPosition.left}px`
          }}
        >
          <button onClick={() => applyFormat('bold')} title="Жирный">
            <span className="toolbar-icon">[B]</span>
          </button>
          <button onClick={() => applyFormat('italic')} title="Курсив">
            <span className="toolbar-icon">[I]</span>
          </button>
          <button onClick={() => applyFormat('code')} title="Код">
            <span className="toolbar-icon">[`]</span>
          </button>
          <button onClick={() => applyFormat('link')} title="Ссылка">
            <span className="toolbar-icon">[🔗]</span>
          </button>
          <button onClick={() => applyFormat('h1')} title="Заголовок 1">
            <span className="toolbar-icon">[H1]</span>
          </button>
          <button onClick={() => applyFormat('h2')} title="Заголовок 2">
            <span className="toolbar-icon">[H2]</span>
          </button>
          <button onClick={() => applyFormat('h3')} title="Заголовок 3">
            <span className="toolbar-icon">[H3]</span>
          </button>
          <button onClick={() => applyFormat('quote')} title="Цитата">
            <span className="toolbar-icon">[>]</span>
          </button>
          <button onClick={() => applyFormat('orderedList')} title="Нумерованный список">
            <span className="toolbar-icon">[1.]</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default InteractiveEditor;
