import React, { useState } from 'react';
import '../styles/CodeBlock.css';

const CodeBlock = ({ children, className }) => {
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div 
      className={`code-block-wrapper ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="code-block-header">
        <div className="code-block-controls">
          <span className="code-block-dot red"></span>
          <span className="code-block-dot yellow"></span>
          <span className="code-block-dot green"></span>
        </div>
        <span className="code-block-title">[>] Command Terminal_</span>
      </div>
      <div className="code-block-content">
        <div className="command-line">
          <span className="prompt">$</span>
          <pre className={`${className} command-text`}>
            <code>{children}</code>
          </pre>
        </div>
        <button 
          className={`copy-command-btn ${copied ? 'copied' : ''}`}
          onClick={handleCopy}
          title="Copy Command"
        >
          <span className="btn-icon">{copied ? '[✓]' : '[⎘]'}</span>
          <span className="btn-text">{copied ? 'Copied' : 'Copy'}</span>
        </button>
      </div>
    </div>
  );
};

export default CodeBlock;
