import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import '../styles/NewsPage.css';
import { useAuth } from '../contexts/AuthContext';

function NewsPage() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [typingTitle, setTypingTitle] = useState('');
  const [hasPrivateAccess, setHasPrivateAccess] = useState(false);
  const { isAdmin } = useAuth();
  
  useEffect(() => {
    const title = '[📰] News Updates_';
    let index = 0;
    const interval = setInterval(() => {
      if (index <= title.length) {
        setTypingTitle(title.slice(0, index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchNews();
  }, [hasPrivateAccess]);

  useEffect(() => {
    checkPrivateAccess();
  }, []);

  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await api.get('/news');
      console.log('News data:', response.data);
      console.log('Has private access:', hasPrivateAccess);
      setNews(response.data);
    } catch (err) {
      console.error('Error fetching news:', err);
      setError(err.response?.data?.message || 'Ошибка при загрузке новостей');
    } finally {
      setLoading(false);
    }
  };

  const checkPrivateAccess = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await api.get('/payments');
        const subscription = response.data.subscription;
        const hasValidSubscription = subscription?.status === 'active' && 
          ['CIPHER_1x', 'KERNEL_2x', 'ROOT_3x', 'PHANTOM_X'].includes(subscription?.type);

        console.log('Access check:', { isAdmin, hasValidSubscription, subscription });
        setHasPrivateAccess(isAdmin || hasValidSubscription);
      } catch (err) {
        console.error('Ошибка при проверке доступа:', err);
        setHasPrivateAccess(false);
      }
    }
  };

  const filteredNews = news.filter(item => {
    if (activeTab === 'all') {
      return !item.is_private || hasPrivateAccess;
    }
    if (activeTab === 'private') {
      return item.is_private && hasPrivateAccess;
    }
    return !item.is_private;
  });

  return (
    <div className="nws-page">
      <h1 className="nws-title">{typingTitle}<span className="nws-cursor">_</span></h1>
      
      <div className="nws-tabs">
        <button 
          className={`nws-tab-btn ${activeTab === 'all' ? 'nws-active' : ''}`}
          onClick={() => setActiveTab('all')}
        >
          [ALL]
        </button>
        <button 
          className={`nws-tab-btn ${activeTab === 'private' ? 'nws-active' : ''}`}
          onClick={() => setActiveTab('private')}
        >
          [PRIVATE ACCESS]
        </button>
        <button 
          className={`nws-tab-btn ${activeTab === 'public' ? 'nws-active' : ''}`}
          onClick={() => setActiveTab('public')}
        >
          [PUBLIC]
        </button>
      </div>

      {loading ? (
        <div className="nws-loading">Загрузка...</div>
      ) : error ? (
        <div className="nws-error">{error}</div>
      ) : (
        <>
          <div className="nws-list">
            {filteredNews.map(item => (
              <div key={item.id} className={`nws-item ${item.is_private ? 'nws-private' : 'nws-public'}`}>
                <div className="nws-item-content">
                  <div className="nws-badge">
                    {item.is_private ? '[🔒]' : '[🌍]'}
                  </div>
                  
                  <div className="nws-main">
                    <div className="nws-header">
                      <h2 className="nws-heading">{item.title}</h2>
                      <span className="nws-date">
                        [🕒] {new Date(item.created_at).toLocaleDateString('ru-RU')}
                      </span>
                    </div>
                    
                    <div className="nws-description">
                      {item.short_description}
                    </div>
                  </div>

                  <Link to={`/news/${item.slug}`} className="nws-read-more">
                    [> Read More]
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {activeTab === 'private' && !hasPrivateAccess && (
            <div className="nws-access-required">
              [🔒] Требуется Private Access подписка для просмотра приватных новостей
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NewsPage;
