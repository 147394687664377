// src/App.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import NetworkBackground from '../components/NetworkBackground';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  return (
    <>
      <Helmet>
      <title>xNod3 - Blockchain Node Deployment Platform</title>
      <meta name="description" content="Deploy blockchain nodes, earn rewards, and take control of your financial future with xNod3" />
      
      {/* Open Graph / Facebook / Telegram */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://xnod3.com/" />
      <meta property="og:title" content="xNod3 - Blockchain Node Deployment Platform" />
      <meta property="og:description" content="Deploy blockchain nodes, earn rewards, and take control of your financial future with xNod3" />
      <meta property="og:image" content="https://xnod3.com/images/p-xnod3.png" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://xnod3.com/" />
      <meta property="twitter:title" content="xNod3 - Blockchain Node Deployment Platform" />
      <meta property="twitter:description" content="Deploy blockchain nodes, earn rewards, and take control of your financial future with xNod3" />
      <meta property="twitter:image" content="https://xnod3.com/images/p-xnod3.png" />
    </Helmet>
      <div className="xn-page-container">
        <NetworkBackground />
        <HeroSection />
        <LiveTerminal />
      </div>
    </>
  );
};

const HeroSection = () => {
  const [wordIndex, setWordIndex] = useState(0);
  const changeableWords = [
    "FUTURE",
    "WEALTH",
    "POWER",
    "FREEDOM"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prev) => (prev + 1) % changeableWords.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="xn-hero-section">
      <div className="xn-hero-content">
        <motion.h1 
          className="xn-hero-title"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          UNLOCK YOUR
          <motion.span 
            key={wordIndex}
            className="xn-hero-title-changing"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {changeableWords[wordIndex]}
          </motion.span>
          <span className="xn-cursor">_</span>
        </motion.h1>
        
        <motion.p 
          className="xn-hero-description"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          We provide you with the tools to deploy blockchain nodes,
          earn rewards, and take control of your financial future.
        </motion.p>

        <motion.div 
          className="xn-hero-buttons"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <Link to="/private" className="xn-hero-button">
            Private Access [P]
          </Link>
          <Link to="/enterprise" className="xn-hero-button">
            Enterprise Solution [E]
          </Link>
        </motion.div>

        <motion.p 
          className="xn-quote"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          "Empowering the blockchain community, one node at a time."
          <span className="xn-quote-author">– xNod3</span>
        </motion.p>

        <StatsDisplay />
      </div>
    </div>
  );
};

const StatsDisplay = () => {
  return (
    <div className="xn-stats-container">
      <div className="xn-stats-grid">
        <div className="xn-stat-item">
          <div className="xn-stat-label">Total Nodes</div>
          <div className="xn-stat-value">1,235</div>
        </div>
        <div className="xn-stat-item">
          <div className="xn-stat-label">APY Range</div>
          <div className="xn-stat-value">8-15%</div>
        </div>
        <div className="xn-stat-item">
          <div className="xn-stat-label">Network Status</div>
          <div className="xn-stat-value">Active</div>
        </div>
      </div>
    </div>
  );
};

const LiveTerminal = () => {
  const [typedLogs, setTypedLogs] = useState([]);
  const logs = [
    { 
      id: 1,
      time: '12:00:01', 
      text: 'Network Status', 
      type: 'status',
      value: 'ACTIVE',
      link: '/private'
    },
    { 
      id: 2,
      time: '12:00:05', 
      text: 'Current APY', 
      type: 'reward',
      value: '+14.5%'
    },
    { 
      id: 3,
      time: '12:00:10', 
      text: 'Active Nodes', 
      type: 'success',
      value: '1,235'
    },
    { 
      id: 4,
      time: '12:00:15', 
      text: 'Total Rewards', 
      type: 'reward',
      value: '$345K'
    }
  ];

  useEffect(() => {
    // Очищаем предыдущие логи
    setTypedLogs([]);
    
    // Инициализация с задержкой
    const initLogs = () => {
      logs.forEach((log, index) => {
        setTimeout(() => {
          setTypedLogs(prev => {
            // Проверяем, нет ли уже такого лога
            if (prev.find(p => p.id === log.id)) return prev;
            return [...prev, log];
          });
        }, index * 1500);
      });
    };

    // Первичная инициализация
    initLogs();

    // Обновление значений каждые 5 секунд
    const updateInterval = setInterval(() => {
      setTypedLogs(current => 
        current.map(log => ({
          ...log,
          time: new Date().toTimeString().slice(0, 8),
          value: updateValue(log)
        }))
      );
    }, 5000);

    return () => clearInterval(updateInterval);
  }, []);

  const updateValue = (log) => {
    switch(log.id) {
      case 2: // APY
        return `+${(14.5 + Math.random() * 0.5).toFixed(1)}%`;
      case 3: // Nodes
        return (1235 + Math.floor(Math.random() * 10)).toLocaleString();
      case 4: // Rewards
        return `$${(345 + Math.random() * 5).toFixed(1)}K`;
      default:
        return log.value;
    }
  };

  return (
    <div className="xn-live-terminal">
      <div className="xn-terminal-header">
        <div className="xn-terminal-title">LIVE NODE STATUS</div>
        <div className="xn-terminal-controls">
          <span className="xn-control xn-control-red"></span>
          <span className="xn-control xn-control-yellow"></span>
          <span className="xn-control xn-control-green"></span>
        </div>
      </div>
      <div className="xn-terminal-body">
        {typedLogs.map((log) => (
          <TypeWriter
            key={log.id}
            log={log}
          />
        ))}
      </div>
      <Link to="/private" className="xn-view-logs-button">
        Access Private Node Network [P]
      </Link>
    </div>
  );
};

const TypeWriter = ({ log }) => {
  const [text, setText] = useState('');
  
  useEffect(() => {
    let currentText = '';
    const textToType = log.text;
    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex < textToType.length) {
        currentText += textToType[currentIndex];
        setText(currentText);
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [log.text]);

  return (
    <div className="xn-terminal-line">
      <span className="xn-timestamp">{log.time}</span>
      <div className={`xn-log-text xn-log-${log.type}`}>
        {log.link ? (
          <Link to={log.link} className="xn-log-link">
            <span>{text}</span>
            <span className="xn-log-value">{log.value}</span>
            <span className="xn-log-arrow">→</span>
          </Link>
        ) : (
          <>
            <span>{text}</span>
            <span className="xn-log-value">{log.value}</span>
          </>
        )}
      </div>
    </div>
  );
};

const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '' : prev + '.');
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return <span className="xn-loading-dots">{dots}</span>;
};


export default HomePage;
