import React, { useState, useEffect } from 'react';
import { projectService } from '../../services/projectService';
import '../../styles/AdminProjects.css';

const AdminProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    description: '',
    logo_url: '',
    status: 'active',
    testnet_status: 'inactive',
    node_version: '',
    is_hidden: false
  });

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const { data } = await projectService.getAllProjects();
      setProjects(data);
    } catch (error) {
      setError('Error loading projects');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const preparedData = {
        name: formData.name.trim(),
        slug: formData.slug.trim(),
        description: formData.description.trim(),
        logo_url: formData.logo_url || null,
        status: formData.status || 'active',
        testnet_status: formData.testnet_status || 'inactive',
        node_version: formData.node_version?.trim() || null,
        is_hidden: formData.is_hidden || false
      };

      if (editingProject) {
        await projectService.updateProject(editingProject.slug, preparedData);
      } else {
        await projectService.createProject(preparedData);
      }
      setModalVisible(false);
      fetchProjects();
      setFormData({
        name: '',
        slug: '',
        description: '',
        logo_url: '',
        status: 'active',
        testnet_status: 'inactive',
        node_version: '',
        is_hidden: false
      });
    } catch (error) {
      setError('Operation failed');
    }
  };

  const handleDelete = async (slug) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await projectService.deleteProject(slug);
        fetchProjects();
      } catch (error) {
        setError('Failed to delete project');
      }
    }
  };

  if (loading) return <div className="projectAdminPage-loading">[>] Loading...</div>;
  if (error) return <div className="projectAdminPage-error">[!] {error}</div>;

  return (
    <div className="projectAdminPage-container">
      <div className="projectAdminPage-header">
        <h1 className="projectAdminPage-title">[🔧] Projects Management_</h1>
        <button 
          className="projectAdminPage-createBtn"
          onClick={() => {
            setEditingProject(null);
            setFormData({
              name: '',
              slug: '',
              description: '',
              logo_url: '',
              status: 'active',
              testnet_status: 'inactive',
              node_version: '',
              is_hidden: false
            });
            setModalVisible(true);
          }}
        >
          [+] Add Project_
        </button>
      </div>

      <div className="projectAdminPage-list">
        {projects.map(project => (
          <div key={project.id} className="projectAdminPage-item">
            <div className="projectAdminPage-status">
              <span className={`projectAdminPage-statusBadge ${project.status}`}>
                [{project.status === 'active' ? '✓' : '◯'}]
              </span>
            </div>
            
            <div className="projectAdminPage-content">
              <h3 className="projectAdminPage-name">{project.name}</h3>
              <div className="projectAdminPage-meta">
                <span className="projectAdminPage-version">[v{project.node_version || 'N/A'}]</span>
                <span className="projectAdminPage-testnet">
                  [{project.testnet_status ? 'Testnet' : 'Mainnet'}]
                </span>
              </div>
              <p className="projectAdminPage-description">{project.description}</p>
            </div>

            <div className="projectAdminPage-actions">
              <button 
                onClick={() => {
                  setEditingProject(project);
                  setFormData(project);
                  setModalVisible(true);
                }} 
                className="projectAdminPage-editBtn"
              >
                [✎]
              </button>
              <button 
                onClick={() => handleDelete(project.slug)} 
                className="projectAdminPage-deleteBtn"
              >
                [×]
              </button>
            </div>
          </div>
        ))}
      </div>

      {modalVisible && (
        <div className="projectAdminPage-modal">
          <div className="projectAdminPage-modalContent">
            <h2 className="projectAdminPage-modalTitle">
              [{editingProject ? '✎' : '+'} Project_]
            </h2>
            <form onSubmit={handleSubmit} className="projectAdminPage-form">
              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Name_</label>
                <input
                  className="projectAdminPage-input"
                  type="text"
                  value={formData.name}
                  onChange={e => setFormData({...formData, name: e.target.value})}
                  required
                />
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Slug_</label>
                <input
                  className="projectAdminPage-input"
                  type="text"
                  value={formData.slug}
                  onChange={e => setFormData({...formData, slug: e.target.value})}
                  required
                />
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Logo URL_</label>
                <input
                  className="projectAdminPage-input"
                  type="text"
                  value={formData.logo_url}
                  onChange={e => setFormData({...formData, logo_url: e.target.value})}
                />
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Node Version_</label>
                <input
                  className="projectAdminPage-input"
                  type="text"
                  value={formData.node_version}
                  onChange={e => setFormData({...formData, node_version: e.target.value})}
                />
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Description_</label>
                <textarea
                  className="projectAdminPage-textarea"
                  value={formData.description}
                  onChange={e => setFormData({...formData, description: e.target.value})}
                />
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Status_</label>
                <select
                  className="projectAdminPage-select"
                  value={formData.status}
                  onChange={e => setFormData({...formData, status: e.target.value})}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Testnet Status_</label>
                <select
                  className="projectAdminPage-select"
                  value={formData.testnet_status}
                  onChange={e => setFormData({...formData, testnet_status: e.target.value})}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="projectAdminPage-formGroup">
                <label className="projectAdminPage-label">[>] Hidden_</label>
                <input
                  className="projectAdminPage-checkbox"
                  type="checkbox"
                  checked={formData.is_hidden}
                  onChange={e => setFormData({...formData, is_hidden: e.target.checked})}
                />
              </div>

              <div className="projectAdminPage-formActions">
                <button type="submit" className="projectAdminPage-submitBtn">
                  [{editingProject ? '✎' : '+'} Save_]
                </button>
                <button 
                  type="button" 
                  className="projectAdminPage-cancelBtn"
                  onClick={() => setModalVisible(false)}
                >
                  [×] Cancel_
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminProjects;