import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthTerminal from './AuthTerminal';
import './ConfigTerminal.css';
import TelegramAccess from '../components/TelegramAccess';
import '../styles/common/TerminalWindow.css';

const ConfigTerminal = () => {
  const [telegramUsername, setTelegramUsername] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentUsername, setCurrentUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [subscription, setSubscription] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    const savedAddress = localStorage.getItem('walletAddress');
    if (token && savedAddress) {
      setIsAuthenticated(true);
      setWalletAddress(savedAddress);
      await fetchCurrentUsername();
      await fetchSubscription(token);
    } else {
      navigate('/'); // Редирект на главную если нет токена
    }
  };

  const fetchCurrentUsername = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/settings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.telegram_username) {
        setCurrentUsername(response.data.telegram_username);
        setTelegramUsername(response.data.telegram_username);
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/');
      }
    }
  };

  const fetchSubscription = async (token) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubscription(res.data.subscription);
    } catch (error) {
      console.error('Ошибка при получении данных подписки:', error);
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/settings/telegram`,
        { telegram_username: telegramUsername },
        { headers: { Authorization: `Bearer ${token}` }}
      );
      
      setCurrentUsername(telegramUsername);
      
      setMessage(`
        ✓ Telegram username успешно сохранен!
        
        ⚡ Для активации доступа:
        1. Перейдите в бот @xnod3_bot
        2. Нажмите кнопку START
      `);
      
    } catch (error) {
      setMessage(error.response?.data?.message || 'Произошла ошибка при сохранении');
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/');
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return <AuthTerminal onAuthenticated={(address) => {
      setIsAuthenticated(true);
      setWalletAddress(address);
      fetchCurrentUsername();
    }} />;
  }

  return (
    <div className="terminal-container">
      <div className="terminal-window">
        <div className="terminal-header">
          <div className="terminal-controls">
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
          </div>
          <span className="terminal-title">xNod3_Config [v1.0]</span>
          <div className="terminal-nav">
            <button onClick={() => navigate('/dashboard')} className="terminal-nav-button">
              [←] BACK TO DASHBOARD
            </button>
          </div>
        </div>

        <div className="terminal-content">
          <div className="config-section">
            <h2 className="section-title">[>] System Configuration<span className="cursor">_</span></h2>
            
            <form onSubmit={handleSubmit} className="config-form">
              <div className="input-group">
                <div className="input-label">
                  <span className="label-icon">[T]</span>
                  <span className="label-text">Telegram Username:</span>
                </div>
                <div className="input-container">
                  <span className="input-prefix">@</span>
                  <input
                    type="text"
                    value={telegramUsername}
                    onChange={(e) => setTelegramUsername(e.target.value)}
                    placeholder="your_telegram_username"
                    className="config-input"
                  />
                </div>
              </div>

              {currentUsername && (
                <div className="current-value">
                  <span className="value-label">[Current]</span>
                  <span className="value-text">@{currentUsername}</span>
                </div>
              )}

              <button type="submit" className="config-submit" disabled={loading}>
                <span className="submit-icon">[⚡]</span>
                <span className="submit-text">
                  {loading ? 'SAVING...' : 'SAVE_CONFIG'}
                </span>
                <span className="submit-arrow">[→]</span>
              </button>
            </form>

            {message && (
              <div className={`config-message ${message.includes('ошибка') ? 'error' : 'success'}`}>
                <span className="message-icon">
                  {message.includes('ошибка') ? '[!]' : '[✓]'}
                </span>
                <span className="message-text">{message}</span>
              </div>
            )}
          </div>

          {subscription?.status === 'active' && (
            <div className="config-section">
              <TelegramAccess 
                subscription={subscription} 
                telegramUsername={currentUsername} 
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigTerminal;
