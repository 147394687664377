import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import api from '../services/api';
import '../styles/GuidesPage.css';

function GuidesPage() {
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await api.get('/guides');
        setGuides(response.data);
      } catch (err) {
        setError('Ошибка при загрузке гайдов');
      } finally {
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  const filteredGuides = guides.filter(guide => {
    const matchesSearch = 
      guide.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      guide.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      guide.tags?.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));
    const matchesCategory = activeTab === 'all' || guide.category === activeTab;
    return matchesSearch && matchesCategory;
  });

  if (loading) return <div className="guides-loading">Загрузка...</div>;
  if (error) return <div className="guides-error">{error}</div>;

  return (
    <div className="guides-list-page">
      <Helmet>
        <title>Guides Library - xNod3</title>
        <meta name="description" content="Explore our comprehensive guide library for blockchain node deployment, tools, and best practices." />
      </Helmet>

      <div className="guides-list-header">
        <h1 className="guides-list-title">[>] Guides Library_</h1>
        <p className="guides-list-description">
          Explore our curated guides for node setup, tools, and tips to enhance your blockchain experience.
        </p>
      </div>

      <div className="guides-list-controls">
        <div className="guides-list-search-bar">
          <span className="guides-list-search-icon">[🔍]</span>
          <input
            type="text"
            placeholder="Search guides..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="guides-list-search-input"
          />
        </div>

        <div className="guides-list-tabs">
          <button 
            className={`guides-list-tab ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => setActiveTab('all')}>
            All Guides
          </button>
          <button 
            className={`guides-list-tab ${activeTab === 'nodes' ? 'active' : ''}`}
            onClick={() => setActiveTab('nodes')}>
            Nodes
          </button>
          <button 
            className={`guides-list-tab ${activeTab === 'tools' ? 'active' : ''}`}
            onClick={() => setActiveTab('tools')}>
            Tools
          </button>
          <button 
            className={`guides-list-tab ${activeTab === 'helpful' ? 'active' : ''}`}
            onClick={() => setActiveTab('helpful')}>
            Helpful Guides
          </button>
        </div>
      </div>

      <div className="guides-list">
        {filteredGuides.map(guide => (
          <div className="guides-list-item" key={guide.id}>
            <div className="guides-list-content">
              <div className="guides-list-info">
                <span className="guides-list-icon">
                  {guide.category === 'nodes' && '[💻]'}
                  {guide.category === 'tools' && '[🛠️]'}
                  {guide.category === 'helpful' && '[📄]'}
                </span>
                <div className="guides-list-text">
                  <h3 className="guides-list-item-title">
                    {guide.title}
                    <div className="guides-list-badges">
                      {guide.is_hot && (
                        <span className="guides-list-badge hot" title="Hot Guide">
                          [🔥]
                        </span>
                      )}
                      {guide.is_active && (
                        <span className="guides-list-badge active" title="Active Guide">
                          [✓]
                        </span>
                      )}
                      {!guide.is_public && (
                        <span className="guides-list-badge private" title="Premium Content">
                          [🔒]
                        </span>
                      )}
                      {guide.status === 'new' && (
                        <span className="guides-list-badge new" title="New Guide">
                          [NEW]
                        </span>
                      )}
                      {guide.status === 'updated' && (
                        <span className="guides-list-badge updated" title="Recently Updated">
                          [UPD]
                        </span>
                      )}
                    </div>
                  </h3>
                  <p className="guides-list-item-description">{guide.description}</p>
                  {guide.tags && guide.tags.length > 0 && (
                    <div className="guides-list-tags">
                      {guide.tags.map(tag => (
                        <span key={tag} className="guides-list-tag">[#] {tag}</span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <Link 
                to={`/guides/${guide.slug}`} 
                className="guides-list-button"
              >
                Open Guide [↗]
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GuidesPage;
