import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import '../styles/AdminPage.css';

function AdminPage() {
  const location = useLocation();
  
  const menuItems = [
    { path: '/admin/guides', icon: '📚', label: 'Гайды' },
    { path: '/admin/projects', icon: '🔧', label: 'Projects' },
    { path: '/admin/wiki', icon: '📖', label: 'Wiki' },
    { path: '/admin/news', icon: '📰', label: 'Новости' },
    { path: '/admin/telegram', icon: '📢', label: 'Telegram' },
    { path: '/admin/settings', icon: '⚙', label: 'Настройки' }
  ];

  return (
    <div className="admin-layout">
      <div className="admin-sidebar">
        <div className="admin-logo">
          [⚡] Admin Panel_
        </div>
        <nav className="admin-nav">
          {menuItems.map(item => (
            <Link
              key={item.path}
              to={item.path}
              className={`admin-nav-item ${location.pathname.startsWith(item.path) ? 'active' : ''}`}
            >
              [{item.icon}] {item.label}_
            </Link>
          ))}
        </nav>
      </div>
      
      <div className="admin-content">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminPage;
