import React from 'react';

const GuideOptions = ({ formData, onChange }) => {
  return (
    <div className="guide-options">
      <div className="options-group">
        <label className="option-item">
          <input
            type="checkbox"
            checked={formData.isHot}
            onChange={(e) => onChange({ ...formData, isHot: e.target.checked })}
          />
          <span className="option-label">[🔥] Hot Guide</span>
        </label>

        <label className="option-item">
          <input
            type="checkbox"
            checked={formData.isActive}
            onChange={(e) => onChange({ ...formData, isActive: e.target.checked })}
          />
          <span className="option-label">[✓] Active</span>
        </label>

        <label className="option-item">
          <input
            type="checkbox"
            checked={formData.isPublic}
            onChange={(e) => onChange({ ...formData, isPublic: e.target.checked })}
          />
          <span className="option-label">[🌐] Public Access</span>
        </label>
      </div>
    </div>
  );
};

export default GuideOptions;