import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminSettings.css';

function AdminSettings() {
  const [settings, setSettings] = useState({
    siteTitle: '',
    siteDescription: '',
    maintenanceMode: false,
    allowRegistration: true,
    defaultUserRole: 'user',
    telegramBot: '',
    discordWebhook: '',
    subscriptionPrice: {
      amount: '27',
      currency: 'USDT'
    }
  });
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/settings`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      setSettings(response.data);
    } catch (err) {
      setError('Ошибка при загрузке настроек');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/settings`,
        settings,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setNotification('Настройки успешно сохранены');
      setTimeout(() => setNotification(null), 3000);
    } catch (err) {
      setError('Ошибка при сохранении настроек');
    }
  };

  if (loading) return <div className="loading">[>] Loading...</div>;

  return (
    <div className="admin-settings">
      <h1>[⚙] Настройки системы_</h1>
      
      {error && (
        <div className="error-message">
          <span className="error-icon">[!]</span>
          <span className="error-text">{error}</span>
        </div>
      )}
      
      {notification && (
        <div className="notification-message">
          <span className="notification-icon">[✓]</span>
          <span className="notification-text">{notification}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="settings-form">
        <div className="settings-section">
          <h2>[>] Основные настройки_</h2>
          <div className="form-group">
            <label>[>] Название сайта_</label>
            <input
              type="text"
              value={settings.siteTitle}
              onChange={e => setSettings({...settings, siteTitle: e.target.value})}
            />
          </div>

          <div className="form-group">
            <label>[>] Описание сайта_</label>
            <textarea
              value={settings.siteDescription}
              onChange={e => setSettings({...settings, siteDescription: e.target.value})}
            />
          </div>
        </div>

        <div className="settings-section">
          <h2>[>] Системные настройки_</h2>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={settings.maintenanceMode}
                onChange={e => setSettings({...settings, maintenanceMode: e.target.checked})}
              />
              [⚠] Режим обслуживания
            </label>
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={settings.allowRegistration}
                onChange={e => setSettings({...settings, allowRegistration: e.target.checked})}
              />
              [👥] Разрешить регистр
            </label>
          </div>

          <div className="form-group">
            <label>[>] Роль по умолчанию_</label>
            <select
              value={settings.defaultUserRole}
              onChange={e => setSettings({...settings, defaultUserRole: e.target.value})}
            >
              <option value="user">Пользователь</option>
              <option value="moderator">Модератор</option>
              <option value="admin">Администратор</option>
            </select>
          </div>
        </div>

        <div className="settings-section">
          <h2>[>] Настройки подписки_</h2>
          <div className="form-group">
            <label>[>] Стоимость подписки_</label>
            <div className="price-input-group">
              <input
                type="number"
                value={settings.subscriptionPrice.amount}
                onChange={e => setSettings({
                  ...settings, 
                  subscriptionPrice: {
                    ...settings.subscriptionPrice,
                    amount: e.target.value
                  }
                })}
                min="0"
                step="0.01"
              />
              <select
                value={settings.subscriptionPrice.currency}
                onChange={e => setSettings({
                  ...settings,
                  subscriptionPrice: {
                    ...settings.subscriptionPrice,
                    currency: e.target.value
                  }
                })}
              >
                <option value="USDT">USDT</option>
                <option value="USDC">USDC</option>
                <option value="BTC">BTC</option>
              </select>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <button type="submit" className="save-btn">
            [✓] Сохранить
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdminSettings;
