import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import '../styles/GuideEditor.css';

// Импортируем новые компоненты
import SectionEditor from '../components/guides/GuideEditor/SectionEditor';
import ImageUploader from '../components/guides/GuideEditor/ImageUploader';
import MarkdownPreview from '../components/guides/GuideEditor/MarkdownPreview';
import GuideMetadata from '../components/guides/GuideEditor/GuideMetadata';
import GuideOptions from '../components/guides/GuideEditor/GuideOptions';
import VersionHistory from '../components/guides/GuideEditor/VersionHistory';
import ImageControls from '../components/guides/GuideEditor/ImageControls';
import ImageLibrary from '../components/guides/GuideEditor/ImageLibrary';
import MarkdownHelp from '../components/guides/GuideEditor/MarkdownHelp';

function GuideEditorPage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(slug);
  const fileInputRef = useRef(null);
  const headerImageInputRef = useRef(null);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    project_id: '',
    category: 'nodes',
    description: '',
    headerImage: '',
    slug: '',
    content: '',
    sections: {
      description: '',
      requirements: '',
      preparation: '',
      installation: '',
      update: '',
      additional: '',
      troubleshooting: ''
    },
    tags: [],
    isHot: false,
    isActive: false,
    isPublic: false
  });
  
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [libraryImages, setLibraryImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [currentSection, setCurrentSection] = useState(null);
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [versions, setVersions] = useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [showMarkdownHelp, setShowMarkdownHelp] = useState(false);

  useEffect(() => {
    if (isEditing) {
      fetchGuide();
    }
  }, [slug]);

  useEffect(() => {
    const handlePasteEvent = (e) => {
      const { event, sectionKey } = e.detail;
      handlePaste(event, sectionKey);
    };

    window.addEventListener('handlePaste', handlePasteEvent);
    return () => window.removeEventListener('handlePaste', handlePasteEvent);
  }, []);

  useEffect(() => {
    if (showImageLibrary) {
      fetchImages();
    }
  }, [showImageLibrary]);

  const fetchGuide = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/guides/${slug}`);
      
      if (response.data.category === 'nodes') {
        const sections = {
          description: '',
          requirements: '',
          preparation: '',
          installation: '',
          update: '',
          additional: '',
          troubleshooting: ''
        };
        
        const content = response.data.content || '';
        const sectionMatches = content.match(/\[(.+?)\]\s*(.+?)_\n\n([\s\S]*?)(?=\n\n\[|$)/g) || [];
        
        sectionMatches.forEach(match => {
          const [, icon, title, content] = match.match(/\[(.+?)\]\s*(.+?)_\n\n([\s\S]*?)$/);
          const sectionKey = {
            'Описание': 'description',
            'Требования для сервера': 'requirements',
            'Подготовка': 'preparation',
            'Установка': 'installation',
            'Обновление': 'update',
            'Дополнительная информация': 'additional',
            'Частые проблемы и их решеня': 'troubleshooting'
          }[title];
          
          if (sectionKey) {
            sections[sectionKey] = content.trim();
          }
        });

        setFormData({
          ...response.data,
          sections,
          isHot: response.data.is_hot || false,
          isActive: response.data.is_active || false,
          isPublic: response.data.is_public || false
        });
      } else {
        setFormData({
          ...response.data,
          content: response.data.content || '',
          isHot: response.data.is_hot || false,
          isActive: response.data.is_active || false,
          isPublic: response.data.is_public || false
        });
      }

      if (response.data) {
        await fetchVersions();
      }
      
    } catch (err) {
      console.error('Error fetching guide:', err);
      setError(err.response?.data?.error || 'Ошибка при загрузке гайда');
    } finally {
      setLoading(false);
    }
  };

  const generateMarkdown = () => {
    if (formData.category === 'nodes') {
      const sections = [
        { key: 'description', title: 'Описание', icon: '⚡' },
        { key: 'requirements', title: 'Требования для сервера', icon: '⚙' },
        { key: 'preparation', title: 'Подготовка', icon: '⚒' },
        { key: 'installation', title: 'Установка', icon: '⚓' },
        { key: 'update', title: 'Обновление', icon: '↻' },
        { key: 'additional', title: 'Дополнительная информция', icon: '' },
        { key: 'troubleshooting', title: 'Частые проблемы и их решения', icon: '⚠' }
      ];

      return sections
        .map(({ key, title, icon }) => {
          const content = formData.sections[key] || '';
          return `[${icon}] ${title}_\n\n${content}\n\n`;
        })
        .join('\n');
    }
    
    return formData.content || '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const submitData = {
        ...formData,
        project_id: formData.project_id || null,
        content: generateMarkdown()
      };

      if (isEditing) {
        await api.put(`/guides/${slug}`, submitData);
      } else {
        await api.post('/guides', submitData);
      }
      navigate('/admin/guides');
    } catch (err) {
      console.error('Error saving guide:', err);
      setError(err.response?.data?.error || 'Ошибка при сохранении гайда');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e, sectionKey = null) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('image', file, file.name);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.url) {
        const imageMarkdown = `![${file.name}](${response.data.url})`;
        handleImageInsert(imageMarkdown, sectionKey);
      }
    } catch (err) {
      console.error('Error uploading image:', err);
      setUploadError(err.response?.data?.error || 'Ошибка при загрузке изображения');
    } finally {
      setUploading(false);
    }
  };

  const handleInsertImage = () => {
    fileInputRef.current?.click();
  };

  const fetchImages = async () => {
    try {
      setLoadingImages(true);
      const response = await api.get('/upload/images');
      setLibraryImages(response.data.images);
    } catch (err) {
      console.error('Error fetching images:', err);
    } finally {
      setLoadingImages(false);
    }
  };

  const handleLibraryOpen = (sectionKey = null) => {
    setCurrentSection(sectionKey);
    setShowImageLibrary(true);
  };

  const handleImageSelect = (imageUrl, publicId) => {
    if (currentSection === 'header') {
      setFormData(prev => ({
        ...prev,
        headerImage: imageUrl
      }));
    } else if (currentSection) {
      const imageMarkdown = `![${publicId}](${imageUrl})`;
      setFormData(prev => ({
        ...prev,
        sections: {
          ...prev.sections,
          [currentSection]: prev.sections[currentSection] + '\n' + imageMarkdown
        }
      }));
    }
    setShowImageLibrary(false);
  };

  const handleImageInsert = (imageMarkdown, sectionKey = null) => {
    if (sectionKey && formData.sections) {
      setFormData(prev => ({
        ...prev,
        sections: {
          ...prev.sections,
          [sectionKey]: prev.sections[sectionKey] + '\n' + imageMarkdown
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        content: prev.content + '\n' + imageMarkdown
      }));
    }
  };

  const handleSectionImageUpload = async (e, sectionKey) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      setUploadError('Только изображения форматов JPG, PNG или GIF');
      return;
    }

    try {
      setUploading(true);
      setUploadError('');

      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.url) {
        const imageMarkdown = `![${file.name}](${response.data.url})`;
        handleImageInsert(imageMarkdown, sectionKey);
      }
    } catch (err) {
      console.error('Error uploading image:', err);
      setUploadError(err.response?.data?.error || 'Ошибка при загрузке изображения');
    } finally {
      setUploading(false);
    }
  };

  const handleInsertHeaderLibraryImage = (imageUrl) => {
    setFormData(prev => ({
      ...prev,
      headerImage: imageUrl
    }));
    setShowImageLibrary(false);
  };

  const handleInsertSectionLibraryImage = (imageUrl, publicId, sectionKey) => {
    const imageMarkdown = `![${publicId}](${imageUrl})`;
    const textarea = document.getElementById(`section-${sectionKey}`);
    const cursorPos = textarea.selectionStart;
    const textBefore = formData.sections[sectionKey].substring(0, cursorPos);
    const textAfter = formData.sections[sectionKey].substring(cursorPos);
    
    setFormData(prev => ({
      ...prev,
      sections: {
        ...prev.sections,
        [sectionKey]: textBefore + imageMarkdown + textAfter
      }
    }));
    setShowImageLibrary(false);
  };

  const handleHeaderImageUpload = async (file) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.url) {
        setFormData(prev => ({
          ...prev,
          headerImage: response.data.url
        }));
      }
    } catch (err) {
      console.error('Error uploading header image:', err);
      setUploadError('Ошибка при загрузке изображения');
    } finally {
      setUploading(false);
    }
  };

  const handleSectionChange = (key, value) => {
    setFormData(prev => ({
      ...prev,
      sections: {
        ...prev.sections,
        [key]: value
      }
    }));
  };

  const fetchVersions = async () => {
    if (!slug) return;
    
    try {
      const response = await api.get(`/guides/${slug}/versions`);
      setVersions(response.data);
    } catch (err) {
      console.error('Error fetching versions:', err);
    }
  };

  useEffect(() => {
    if (showVersions && slug) {
      fetchVersions();
    }
  }, [showVersions, slug]);

  const handleVersionRestore = async (version) => {
    try {
      const response = await api.post(`/guides/${slug}/restore`, { version });
      if (response.data) {
        setFormData(response.data);
        setShowVersions(false);
      }
    } catch (err) {
      console.error('Error restoring version:', err);
      setError('Ошибка при восстановлении версии');
    }
  };

  const handlePaste = async (event, sectionKey = null) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    const imageItem = Array.from(items).find(item => item.type.startsWith('image'));
    if (!imageItem) return;

    try {
      setUploading(true);
      const file = imageItem.getAsFile();
      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.url) {
        const imageMarkdown = `![](${response.data.url})`;
        handleImageInsert(imageMarkdown, sectionKey);
      }
    } catch (err) {
      console.error('Error uploading pasted image:', err);
      setUploadError('Ошибка при загрузке изображения');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="guide-editor-page">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <span className="loading-icon">[⟳]</span>
            <span className="loading-text">Сохранение гайда...</span>
          </div>
        </div>
      )}
      {error && (
        <div className="error-message">
          <span className="error-icon">[!]</span>
          <span className="error-text">{error}</span>
        </div>
      )}
      <MarkdownPreview content={formData.category === 'nodes' ? generateMarkdown() : formData.content} />

      <div className="editor-form">
        <div className="editor-toolbar">
          <button 
            type="button" 
            onClick={() => setShowMarkdownHelp(true)} 
            className="toolbar-button"
          >
            [?] Markdown Help
          </button>
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <ImageControls 
              headerImage={formData.headerImage}
              onHeaderImageChange={(value) => setFormData(prev => ({ ...prev, headerImage: value }))}
              onHeaderImageUpload={handleHeaderImageUpload}
              onLibraryOpen={() => handleLibraryOpen('header')}
              onSectionImageUpload={handleImageUpload}
              uploadError={uploadError}
            />

            <GuideMetadata 
              formData={formData}
              onChange={setFormData}
            />

            {formData.category === 'nodes' ? (
              <SectionEditor 
                sections={formData.sections}
                onChange={handleSectionChange}
                getSectionIcon={getSectionIcon}
                getSectionTitle={getSectionTitle}
                getSectionPlaceholder={getSectionPlaceholder}
                onPaste={handlePaste}
              />
            ) : (
              <div className="guide-content">
                <h3>Содержание</h3>
                <textarea
                  className="section-editor"
                  value={formData.content || ''}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    content: e.target.value 
                  }))}
                  placeholder="Введите содержание гайда..."
                  onPaste={(e) => handlePaste(e)}
                />
              </div>
            )}

            <GuideOptions 
              formData={formData}
              onChange={setFormData}
            />

            <div className="form-actions">
              <button 
                type="button" 
                onClick={() => setShowVersions(true)} 
                className="version-btn"
              >
                [↻] История
              </button>
              <button type="button" onClick={() => navigate('/admin/guides')} className="cancel-btn">
                [←] Отмена
              </button>
              <button type="submit" className="save-btn">
                [✓] Сохранить
              </button>
            </div>
          </form>
        )}
      </div>
      
      {showImageLibrary && (
        <ImageLibrary 
          images={libraryImages}
          loading={loadingImages}
          onSelect={handleImageSelect}
          onClose={() => setShowImageLibrary(false)}
        />
      )}

      {notification && (
        <div className={`notification ${notificationType}`}>
          {notification}
        </div>
      )}

      {showVersions && (
        <VersionHistory
          versions={versions}
          onRestore={handleVersionRestore}
          onClose={() => setShowVersions(false)}
        />
      )}

      {showMarkdownHelp && (
        <MarkdownHelp onClose={() => setShowMarkdownHelp(false)} />
      )}
    </div>
  );
}

// Вспомогательные функции
const getSectionTitle = (key) => {
  const titles = {
    description: 'Описание',
    requirements: 'Требования для сервера',
    preparation: 'Подготовка',
    installation: 'Установка',
    update: 'Обновление',
    additional: 'Дополнительная информация',
    troubleshooting: 'Частые проблемы и их решения'
  };
  return titles[key];
};

const getSectionPlaceholder = (key) => {
  const placeholders = {
    description: 'Общее описание ноды...',
    requirements: 'Требования к серверу...',
    preparation: 'Шаги подготовки...',
    installation: 'Процесс установки...',
    update: 'Инструкции по обновлению...',
    additional: 'Дополнительная информация...',
    troubleshooting: 'Решение проблем...'
  };
  return placeholders[key];
};

const getSectionIcon = (key) => {
  const icons = {
    description: '⚡',
    requirements: '⚙',
    preparation: '⚒',
    installation: '⚓',
    update: '↻',
    additional: '✎',
    troubleshooting: '⚠'
  };
  return icons[key] || '✎';
};

export default GuideEditorPage;
