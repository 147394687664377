import React from 'react';

const VersionHistory = ({ versions, onRestore, onClose }) => {
  return (
    <>
      <div className="version-history-overlay" onClick={onClose} />
      <div className="version-history-modal">
        <h3>[↻] История версий</h3>
        <div className="versions-list">
          {versions.map(version => (
            <div key={version.id} className="version-item">
              <div className="version-info">
                <span className="version-number">v{version.version}</span>
                <span className="version-date">
                  {new Date(version.created_at).toLocaleString()}
                </span>
                <span className="version-editor">{version.editor_name}</span>
              </div>
              <button 
                onClick={() => onRestore(version)}
                className="restore-button"
              >
                [↺] Восстановить
              </button>
            </div>
          ))}
        </div>
        <button onClick={onClose} className="close-button">
          [×] Закрыть
        </button>
      </div>
    </>
  );
};

export default VersionHistory;
