import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../../CodeBlock';

const MarkdownPreview = ({ content }) => {
  return (
    <div className="markdown-preview">
      <div className="preview-header">
        <span className="preview-title">[>] Preview_</span>
      </div>
      <div className="preview-content">
        <ReactMarkdown
          components={{
            code: ({node, inline, className, children, ...props}) => {
              if (inline) {
                return <code className={className} {...props}>{children}</code>;
              }
              return (
                <CodeBlock className={className}>
                  {String(children).replace(/\n$/, '')}
                </CodeBlock>
              );
            }
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default MarkdownPreview;