import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import '../styles/AdminWikiPage.css';

function AdminWikiPage() {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', icon: '' });
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [articlesRes, categoriesRes] = await Promise.all([
          api.get('/wiki/admin/articles'),
          api.get('/wiki/categories')
        ]);
        setArticles(articlesRes.data);
        setCategories(categoriesRes.data);
      } catch (err) {
        setError('Ошибка при загрузке данных');
        console.error('Error fetching wiki data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteArticle = async (slug) => {
    if (window.confirm('Вы уверены, что хотите удалить эту статью?')) {
      try {
        await api.delete(`/wiki/articles/${slug}`);
        setArticles(articles.filter(article => article.slug !== slug));
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/wiki/categories', newCategory);
      setCategories([...categories, response.data]);
      setNewCategory({ name: '', icon: '' });
      setShowCategoryModal(false);
    } catch (err) {
      console.error('Error adding category:', err);
    }
  };

  const handleDeleteCategory = async (id) => {
    if (window.confirm('Вы уверены, что хотите удалить эту категорию?')) {
      try {
        await api.delete(`/wiki/categories/${id}`);
        setCategories(categories.filter(cat => cat.id !== id));
      } catch (err) {
        console.error('Error deleting category:', err);
      }
    }
  };

  const handleUpdateCategory = async (e, id) => {
    e.preventDefault();
    try {
      await api.put(`/wiki/categories/${id}`, editingCategory);
      setCategories(categories.map(cat => 
        cat.id === id ? {...cat, ...editingCategory} : cat
      ));
      setEditingCategory(null);
    } catch (err) {
      console.error('Error updating category:', err);
    }
  };

  if (loading) return <div className="admin-wiki-loading">[>] Loading...</div>;
  if (error) return <div className="admin-wiki-error">[!] {error}</div>;

  return (
    <div className="admin-wiki-page">
      <div className="admin-wiki-header">
        <div className="admin-wiki-title">
          <h1>[📖] Wiki Management</h1>
        </div>
        <div className="admin-wiki-actions">
          <button 
            onClick={() => setShowCategoryModal(true)}
            className="admin-wiki-btn"
          >
            [+] Add Category
          </button>
          <Link to="/admin/wiki/new" className="admin-wiki-btn primary">
            [+] New Article
          </Link>
        </div>
      </div>

      <div className="admin-wiki-categories">
        <h2>[📑] Categories</h2>
        <div className="categories-grid">
          {categories.map(category => (
            <div key={category.id} className="category-item">
              {editingCategory?.id === category.id ? (
                <form onSubmit={(e) => handleUpdateCategory(e, category.id)} className="category-edit-form">
                  <input
                    type="text"
                    value={editingCategory.name}
                    onChange={(e) => setEditingCategory({...editingCategory, name: e.target.value})}
                    placeholder="Название"
                  />
                  <input
                    type="text"
                    value={editingCategory.icon}
                    onChange={(e) => setEditingCategory({...editingCategory, icon: e.target.value})}
                    placeholder="Иконка"
                  />
                  <button type="submit">[✓]</button>
                  <button type="button" onClick={() => setEditingCategory(null)}>[×]</button>
                </form>
              ) : (
                <>
                  <span className="category-icon">[{category.icon}]</span>
                  <span className="category-name">{category.name}</span>
                  <button 
                    onClick={() => setEditingCategory(category)}
                    className="category-edit-btn"
                  >
                    [✎]
                  </button>
                  <button 
                    onClick={() => handleDeleteCategory(category.id)}
                    className="category-delete-btn"
                  >
                    [×]
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {articles.length === 0 ? (
        <div className="admin-wiki-empty">
          [!] No articles found. Create your first Wiki article.
        </div>
      ) : (
        <div className="admin-wiki-list">
          {articles.map(article => (
            <div key={article.id} className="admin-wiki-item">
              <div className="admin-wiki-item-info">
                <h3>{article.title}</h3>
                <p className="admin-wiki-item-meta">
                  <span className="category">
                    [{categories.find(c => c.id === article.category_id)?.name || 'Uncategorized'}]
                  </span>
                  <span className="status">[{article.status}]</span>
                  <span className="views">[👁 {article.views || 0}]</span>
                </p>
              </div>
              <div className="admin-wiki-item-actions">
                <Link 
                  to={`/admin/wiki/edit/${article.slug}`} 
                  className="admin-wiki-edit-btn"
                >
                  [✎] Edit
                </Link>
                <button 
                  onClick={() => handleDeleteArticle(article.slug)}
                  className="admin-wiki-delete-btn"
                >
                  [×] Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {showCategoryModal && (
        <div className="admwiki-modal-overlay">
          <div className="admwiki-modal-content">
            <h2>[+] Add Category</h2>
            <form onSubmit={handleAddCategory}>
              <input
                type="text"
                placeholder="Category Name"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                required
              />
              <input
                type="text"
                placeholder="Icon (emoji)"
                value={newCategory.icon}
                onChange={(e) => setNewCategory({ ...newCategory, icon: e.target.value })}
                required
              />
              <div className="admwiki-modal-actions">
                <button type="button" onClick={() => setShowCategoryModal(false)}>
                  [←] Cancel
                </button>
                <button type="submit">
                  [✓] Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminWikiPage;
