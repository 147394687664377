// src/components/AuthTerminal.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AuthTerminal.css';
import api from '../services/api';

const AuthTerminal = ({ onAuthenticated }) => {
  const [terminalState, setTerminalState] = useState('initializing');
  const [terminalLines, setTerminalLines] = useState([]);
  const [authLink, setAuthLink] = useState('');
  const [authCode, setAuthCode] = useState('');
  const navigate = useNavigate();
  const terminalEndRef = useRef(null);
  const pollInterval = useRef(null);

  const addTerminalLine = (text, type = 'info') => {
    const timestamp = new Date().toLocaleTimeString();
    setTerminalLines(prev => [...prev, { text, type, timestamp }]);
  };

  useEffect(() => {
    let mounted = true;
    
    const initializeTerminal = async () => {
      const initLines = [
        { text: 'Initializing xNod3_Auth_Terminal...', type: 'system' },
        { text: 'Loading security protocols...', type: 'system' },
        { text: 'Establishing secure connection...', type: 'system' },
        { text: 'Ready for authentication', type: 'success' }
      ];

      for (const line of initLines) {
        if (!mounted) return;
        await new Promise(resolve => setTimeout(resolve, 500));
        addTerminalLine(line.text, line.type);
      }

      if (!mounted) return;
      setTerminalState('ready');
    };

    initializeTerminal();
    return () => { mounted = false; };
  }, []);

  const checkAuthStatus = async (code) => {
    try {
      if (!code) {
        console.error('No auth code provided');
        return;
      }
      
      console.log('Checking auth status with code:', code);
      
      const response = await api.post('/auth/telegram/check-status', {
        auth_code: code
      });
      
      console.log('Auth status response:', response.data);
      
      if (response.data.status === 'authenticated') {
        console.log('Authentication successful, saving tokens');
        clearInterval(pollInterval.current);
        
        addTerminalLine('Confirmation received [✓]', 'success');
        addTerminalLine('Verifying security tokens...', 'system');
        
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('telegram_username', response.data.telegram_username);
        
        addTerminalLine('Tokens successfully stored [✓]', 'success');
        addTerminalLine('Initializing session...', 'system');
        addTerminalLine(`User ${response.data.telegram_username} successfully authenticated [✓]`, 'success');
        
        console.log('Calling onAuthenticated with:', response.data.telegram_username);
        onAuthenticated?.(response.data.telegram_username);
        
        addTerminalLine('Redirecting to dashboard...', 'system');
        setTimeout(() => navigate('/dashboard'), 1500);
      }
    } catch (error) {
      console.error('Auth status check failed:', error);
      addTerminalLine('Authentication status check failed [✗]', 'error');
    }
  };

  const generateAuthLink = async () => {
    try {
      setTerminalState('connecting');
      addTerminalLine('Generating secure authentication link...', 'system');
      addTerminalLine('Initializing Telegram Auth protocol...', 'system');
      
      const response = await api.post('/auth/telegram/generate-link');
      console.log('Generated auth link response:', response.data);
      
      if (response.data.authLink && response.data.authCode) {
        const code = response.data.authCode;
        setAuthLink(response.data.authLink);
        setAuthCode(code);
        
        addTerminalLine('Link successfully generated [✓]', 'success');
        addTerminalLine('Awaiting Telegram connection...', 'system');
        addTerminalLine('Click the button below to proceed with authentication', 'info');
        setTerminalState('waiting');

        pollInterval.current = setInterval(() => {
          checkAuthStatus(code);
        }, 1000);

        setTimeout(() => {
          if (pollInterval.current) {
            clearInterval(pollInterval.current);
            setTerminalState('error');
            addTerminalLine('Authentication timeout. Please try again [✗]', 'error');
          }
        }, 15 * 60 * 1000);
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Auth link generation failed:', error);
      addTerminalLine('Failed to generate authentication link [✗]', 'error');
      setTerminalState('error');
    }
  };

  const handleTelegramClick = () => {
    addTerminalLine('Initiating Telegram authentication...', 'system');
    addTerminalLine('Waiting for Telegram confirmation...', 'pending');
    window.open(authLink, '_blank');
  };

  // Очистка при размонтировании
  useEffect(() => {
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    };
  }, []);

  return (
    <div className="terminal-container">
      <div className="auth-terminal-wrapper">
        <div className="auth-terminal-box">
          <div className="auth-terminal-header">
            <div className="auth-terminal-controls-group">
              <div className="auth-terminal-control-btn close"></div>
              <div className="auth-terminal-control-btn minimize"></div>
              <div className="auth-terminal-control-btn maximize"></div>
            </div>
            <div className="auth-terminal-title-text">xNod3_Auth_Terminal [v2.0]</div>
            <div className={`auth-terminal-status-indicator ${terminalState}`}>
              {terminalState.toUpperCase()}
            </div>
          </div>

          <div className="auth-terminal-content">
            <div className="auth-terminal-output-area">
              {terminalLines.map((line, index) => (
                <div key={index} className={`auth-terminal-line-item ${line.type}`}>
                  <span className="auth-terminal-prefix">> </span>
                  <span className="auth-terminal-text">{line.text}</span>
                  <span className="auth-terminal-timestamp"> [{line.timestamp}]</span>
                </div>
              ))}
              <div ref={terminalEndRef} />
            </div>

            {terminalState === 'ready' && (
              <div className="auth-terminal-input-area">
                <button onClick={generateAuthLink} className="auth-terminal-connect-btn">
                  AUTHENTICATE [⏎]
                </button>
              </div>
            )}
            
            {terminalState === 'waiting' && (
              <div className="auth-terminal-input-area">
                <button 
                  className="auth-button"
                  onClick={handleTelegramClick}
                >
                  <span>[🔐]</span>
                  CONNECT_VIA_TELEGRAM
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTerminal;
