import React, { useRef, useEffect } from 'react';
import '../styles/BackgroundMatrix.css';

const MATRIX_CHARS = '01';
const FONT_SIZE = 10;
const DROP_SPEED = 1;

function BackgroundMatrix() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    let columns;
    let drops = [];
    
    const initMatrix = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      
      columns = Math.floor(canvas.width / FONT_SIZE);
      drops = Array(columns).fill(1);
      
      ctx.font = `${FONT_SIZE}px "Anonymous Pro"`;
    };

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      ctx.fillStyle = 'rgba(0, 255, 0, 0.1)';
      
      for (let i = 0; i < drops.length; i++) {
        const char = MATRIX_CHARS[Math.floor(Math.random() * MATRIX_CHARS.length)];
        ctx.fillText(char, i * FONT_SIZE, drops[i] * FONT_SIZE);
        
        if (drops[i] * FONT_SIZE > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i] += DROP_SPEED;
      }
    };

    initMatrix();
    window.addEventListener('resize', initMatrix);

    const animationInterval = setInterval(draw, 50);

    return () => {
      clearInterval(animationInterval);
      window.removeEventListener('resize', initMatrix);
    };
  }, []);

  return <canvas ref={canvasRef} className="background-matrix" />;
}

export default BackgroundMatrix; 