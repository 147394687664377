export const translations = {
  en: {
    title: "Private Access: Exclusive Node Tools & Support",
    meta: {
      title: "Private Access - Exclusive Blockchain Tools & Community | xNod3",
      description: "Gain exclusive access to xNod3's private community, offering advanced blockchain scripts, early updates, and personalized technical support. Join us today!",
      keywords: "private access, blockchain scripts, exclusive tools, xNod3 community, early updates, technical support",
    },
    headerDescription: "Gain access to our exclusive library of custom node scripts, expert support, and tools tailored for individual blockchain enthusiasts.",
    features: [
      {
        id: 'scripts',
        title: 'Custom Node Scripts',
        headerDescription: "Gain access to our exclusive library of custom node scripts, expert support, and tools tailored for individual blockchain enthusiasts.",
        description: 'Access a library of scripts crafted for easy and efficient node deployment across various blockchain networks.',
        action: 'View Example Script'
      },
      {
        id: 'support',
        title: 'Expert Technical Support',
        icon: '[?]',
        description: 'Receive personalized support for node setup and troubleshooting directly from our experts.',
        action: 'Open Support Chat'
      },
      {
        id: 'community',
        title: 'Community and Updates',
        icon: '[#]',
        description: 'Be the first to access new scripts, updates, and insights from the xNod3 team and private community.',
        action: 'Join Community'
      }
    ],
    supportMessages: [
      { 
        sender: 'expert', 
        text: 'Yo! 👋 Welcome to xNod3 support! What can we hack together today?' 
      },
      { 
        sender: 'user', 
        text: "Hey there! I'm pulling my hair out trying to get this Ritual node running 😅 Not even sure if my setup is good enough..." 
      },
      { 
        sender: 'expert', 
        text: "Don't sweat it, we've all been there! 🛠️ Let's make sure your machine can handle it first. Ritual's pretty chill - needs 4GB RAM and 40GB storage. What're you working with?" 
      },
      { 
        sender: 'user', 
        text: "Oh sweet, I've got way more than that! Running on a beast with 8GB RAM 💪" 
      },
      { 
        sender: 'expert', 
        text: "Perfect, you're gonna crush it! 🚀 Here's the magic one-liner that'll do all the heavy lifting:", 
        command: 'bash <(curl -s https://scripts.xnod3.com/nodes/ritual-wizzard.sh)' 
      },
      { 
        sender: 'user', 
        text: "You're a lifesaver! 🙏 Firing it up now!" 
      },
      { 
        sender: 'expert', 
        text: "Let it rip! 🎮 Hit me up if anything looks funky. And hey, while it's installing, wanna hear about this sick APY we're seeing on the testnet? 👀" 
      }
    ],
    installationLogs: [
      { text: 'Initializing Ritual Node Setup...', type: 'info' },
      { text: 'Checking system requirements...', type: 'info' },
      { text: 'Downloading necessary files...', type: 'info' },
      { text: '> ritual-node-package.tar.gz [✓]', type: 'success' },
      { text: '> dependencies [✓]', type: 'success' },
      { text: 'Configuring node parameters...', type: 'info' },
      { text: 'Starting node...', type: 'info' },
      { text: 'Ritual node installation complete.', type: 'success' },
      { text: 'Node status: Online', type: 'success' }
    ],
    updates: [
      {
        date: '03/05/2024',
        title: 'New Ritual Node Script Released!',
        description: 'Optimize your Ritual node setup with our latest script update. Improved performance and compatibility with the latest network version.',
        type: 'update',
        isNew: true
      },
      {
        date: '03/20/2024',
        title: 'Exclusive Webinar: Optimizing Node ROI',
        description: 'Join the xNod3 team for an in-depth session on maximizing node profitability.',
        type: 'webinar',
        eventDate: 'March 20, 2024, 3 PM UTC',
        isNew: false
      }
    ],
    communityTip: {
      header: '> Weekly Community Tip',
      text: 'For optimized node performance, try combining our custom scripts with auto-restart features. This reduces downtime by up to 20%!',
      author: '— Submitted by NodeMaster42'
    },
    notificationButtons: {
      enable: '[>] Enable Notifications',
      enabled: '[✓] Notifications Enabled',
      note: 'You will receive updates directly in your Private Access feed.'
    },
    faqData: [
      {
        id: 1,
        question: 'What is included in Private Access?',
        answer: [
          'Private Access membership provides exclusive access to:',
          '> Node Scripts: Tested and optimized deployment scripts',
          '> Personalized Support: 1:1 assistance from our experts',
          '> Early Updates: First access to new features and improvements',
          '',
          'Note: Perfect for individual blockchain enthusiasts and small businesses.'
        ]
      },
      {
        id: 2,
        question: 'How do I pay for my subscription?',
        answer: [
          'Cryptocurrency payments only:',
          '> Monthly subscription: $30/month',
          '> Supported coins: BTC, ETH, USDT, USDC',
          '> Flexible billing cycles available',
          '',
          'Note: Long-term subscriptions receive special discounts.'
        ]
      },
      {
        id: 3,
        question: 'Can I cancel anytime?',
        answer: [
          '✅ Yes! Our flexible terms include:',
          '> No cancellation penalties',
          '> Access until billing period ends',
          '> Easy reactivation available',
          '',
          'Note: Cancel directly from your dashboard anytime.'
        ]
      }
    ],
    pricing: {
      sectionTitle: "> Access the Inner Circle",
      description: "Unlock exclusive guides, tools, and profit strategies",
      planSelector: {
        monthly: "Monthly",
        sixMonths: "6 Months",
        twelveMonths: "12 Months",
        savings: {
          sixMonths: "-10%",
          twelveMonths: "-17%"
        }
      },
      plans: {
        monthly: {
          title: "[Monthly Plan]",
          icon: "[🔄]",
          pricePerMonth: "{{amount}}"
        },
        sixMonths: {
          title: "[6 Months Plan]",
          icon: "[🔒]",
          priceTotal: "{{amount}}",
          pricePerMonth: "{{perMonth}}",
          savePercent: "10"
        },
        twelveMonths: {
          title: "[12 Months Plan]",
          icon: "[🌟]",
          priceTotal: "{{amount}}",
          pricePerMonth: "{{perMonth}}",
          savePercent: "17"
        }
      },
      benefits: [
        "Full Access to Private Scripts",
        "Priority Support 24/7",
        "Early Access to New Features",
        "Community Networking"
      ],
      button: {
        text: "Join Now",
        icon: "[⚙️]",
        loading: "Processing..."
      },
      footer: {
        secureBadge: "[🔒 Secure Payment]",
        note: "Cancel anytime • Crypto payments accepted"
      },
      exclusiveNote: "[🔒] Access granted only to members of the Inner Circle",
      tips: [
        "[💡] Did you know? Long-term plans save you up to 17%",
        "[🔧] Use our tools to deploy Ritual and earn rewards",
        "[🛡️] Joining Private Access unlocks 100+ tested scripts"
      ]
    },
    interface: {
      copyButton: {
        default: "[>] Copy Command",
        copied: "[✓] Copied"
      },
      terminal: {
        title: "Installation Command",
        cursor: "_"
      },
      preview: {
        scriptTitle: "> Sample Script Preview",
        scriptDescription: "Setting up a Ritual node is easy with our custom script. Simply run the command below, and follow the on-screen instructions.",
        scriptCommand: "bash <(curl -s https://scripts.xnod3.com/nodes/ritual-wizzard.sh)",
        supportTitle: "> Expert Technical Support",
        supportDescription: "Receive personalized support for node setup and troubleshooting directly from our experts.",
        communityTitle: "> Community and Updates",
        communityDescription: "Be the first to access new scripts, updates, and insights from the xNod3 team and private community.",
        communitySubDescription: "Join a network of blockchain enthusiasts and industry experts, stay ahead with exclusive content, and make your node operations more efficient with insider knowledge."
      }
    }
  },
  ru: {
    title: "Приватный доступ: Эксклюзивные инструменты и поддержка",
    meta: {
      title: "Приватный доступ - Эксклюзивные блокчейн инструменты и сообщество | xNod3",
      description: "Получите эксклюзивный доступ к закрытому сообществу xNod3, предлагающему продвинутые блокчейн скрипты, ранние обновления и персональную техническую поддержку. Присоединяйтесь сегодня!",
      keywords: "приватный доступ, блокчейн скрипты, эксклюзивные инструменты, сообщество xNod3, ранние обновления, техническая поддержка",
    },
    headerDescription: "Получите доступ к нашей эксклюзивной библиотеке пользовательских скриптов, экспертной поддержке и инструментам для энтузиастов блокчейна.",
    features: [
      {
        id: 'scripts',
        title: 'Пользовательские скрипты',
        headerDescription: "Получите доступ к нашей эксклюзивной библиотеке пользовательских скриптов, экспертной поддержке и инструментам для энтузиастов блокчейна.",
        description: 'Доступ к библиотеке скриптов для простого и эффективного развертывания нод в различных блокчейн-сетях.',
        action: 'Посмотреть пример скрипта'
      },
      {
        id: 'support',
        title: 'Экспертная ехническая поддержка',
        icon: '[?]',
        description: 'Получите персональную поддержку по настройке и устранению неполадок нод   напрямую от наших экспертов.',
        action: 'Открыть чат поддержки'
      },
      {
        id: 'community',
        title: 'Сообщество и обновления',
        icon: '[#]',
        description: 'Будьте первыми, кто получит доступ к новым скриптам, обновлениям и инсайтам от команды xNod3 и закрытого сообщества.',
        action: 'Присоединиться к сообществу'
      }
    ],
    supportMessages: [
      { 
        sender: 'expert', 
        text: 'Привет! 👋 Добро пожаловать в поддержку xNod3! Что будем хачить сегодня?' 
      },
      { 
        sender: 'user', 
        text: "Привет! Я тут голову ломаю, пытаясь запустить узел Ritual 😅 Даже не уверен, что моя конфигурация подойдет..." 
      },
      { 
        sender: 'expert', 
        text: "Не переживай, все через эо проходили! 🛠️ Давай сначала проверим, потянет ли твоя машина. Ritual довольно неприхотливый - нужно 4ГБ оперативки и 40ГБ места. Что у тебя?" 
      },
      { 
        sender: 'user', 
        text: "О, супер, у меня даже больше! Работаю на мощной машине с 8ГБ оперативки 💪" 
      },
      { 
        sender: 'expert', 
        text: "Отлично, всё получится! 🚀 Вот волшебная команда, которая всё сделает за тебя:", 
        command: 'bash <(curl -s https://scripts.xnod3.com/nodes/ritual-wizzard.sh)' 
      },
      { 
        sender: 'user', 
        text: "Ты спаситель! 🙏 Запускаю прямо сейчас!" 
      },
      { 
        sender: 'expert', 
        text: "Поехали! 🎮 Пиши, если что-то пойдёт не так. И кстати, пока идёт установка, хочешь узнать про сумасшедший APY, который мы видим в тестнете? 👀" 
      }
    ],
    installationLogs: [
      { text: 'Инициализация настройки ноды Ritual...', type: 'info' },
      { text: 'Проверка системных требований...', type: 'info' },
      { text: 'Загрузка необходимых файлов...', type: 'info' },
      { text: '> ritual-node-package.tar.gz [✓]', type: 'success' },
      { text: '> зависимости [✓]', type: 'success' },
      { text: 'Настройка параметров ноды...', type: 'info' },
      { text: 'Запуск ноды...', type: 'info' },
      { text: 'Установка ноды Ritual завершена.', type: 'success' },
      { text: 'Статус ноды: В сети', type: 'success' }
    ],
    updates: [
      {
        date: '05.03.2024',
        title: 'Выпущен новый скрипт для ноды Ritual!',
        description: 'Оптимизируйте настройку вашей ноды Ritual с нашим последним обновлением скрипта. Улучшена производительность и совместимость с последней версией сети.',
        type: 'update',
        isNew: true
      },
      {
        date: '20.03.2024',
        title: 'Эксклюзивный вебинар: Оптимизация ROI ноды',
        description: 'Присоединяйтесь к команде xNod3 для подробного рзора способов максимизации прибыльности нод.',
        type: 'webinar',
        eventDate: '20 марта 2024, 15:00 UTC',
        isNew: false
      }
    ],
    communityTip: {
      header: '> Еженедельный совет сообщества',
      text: 'Для оптимизации производительности ноды попробуйте комбинировать наши скрипты с функцией автоперезапуска. Это снижает время простоя до 20%!',
      author: '— Предложено NodeMaster42'
    },
    notificationButtons: {
      enable: '[>] Включить уведомления',
      enabled: '[✓] Уведомления включены',
      note: 'Вы будете получать обновления прямо в вашей ленте Private Access.'
    },
    faqData: [
      {
        id: 1,
        question: 'Что включено в Приватный доступ?',
        answer: [
          'Членство Private Access предоставляет эксклюзивный доступ к:',
          '> Скрипты для ноды: Протестированные и оптимизированные скрипты развертывания',
          '> Персонаьная поддержка: Индивидуальная помощь от наших экспертов',
          '> Ранний доступ: Первыми получаете доступ к новым функциям и улучшениям',
          '',
          'Примечание: Идеально подходит для энтузиастов блокчейна и малого бизнеса.'
        ]
      },
      {
        id: 2,
        question: 'Как оплатить подписку?',
        answer: [
          'Только криптовалютные платежи:',
          '> Месячная подписка: $30/месяц',
          '> Поддерживаемые монеты: BTC, ETH, USDT, USDC',
          '> Доступны гибкие циклы оплаты',
          '',
          'Примечание: Долгосрочные подписки получают специальные скидки.'
        ]
      },
      {
        id: 3,
        question: 'Могу ли я отменить подписку в любое время?',
        answer: [
          '✅ Да! Наши гибкие условия включают:',
          '> Никаких штрафов за отмену',
          '> Доступ до конца оплаченного периода',
          '> Простая повторная активация',
          '',
          'Примечание: Отменить подписку можно в любое время прямо из панели управления.'
        ]
      }
    ],
    pricing: {
      sectionTitle: "> Access the Inner Circle",
      description: "Unlock exclusive guides, tools, and profit strategies",
      planSelector: {
        monthly: "Monthly",
        sixMonths: "6 Months",
        twelveMonths: "12 Months",
        savings: {
          sixMonths: "-10%",
          twelveMonths: "-17%"
        }
      },
      plans: {
        monthly: {
          title: "[Monthly Plan]",
          icon: "[🔄]",
          pricePerMonth: "{{amount}}"
        },
        sixMonths: {
          title: "[6 Months Plan]",
          icon: "[🔒]",
          priceTotal: "{{amount}}",
          pricePerMonth: "{{perMonth}}",
          savePercent: "10"
        },
        twelveMonths: {
          title: "[12 Months Plan]",
          icon: "[🌟]",
          priceTotal: "{{amount}}",
          pricePerMonth: "{{perMonth}}",
          savePercent: "17"
        }
      },
      benefits: [
        "Full Access to Private Scripts",
        "Priority Support 24/7",
        "Early Access to New Features",
        "Community Networking"
      ],
      button: {
        text: "Join Now",
        icon: "[⚙️]",
        loading: "Processing..."
      },
      footer: {
        secureBadge: "[🔒 Secure Payment]",
        note: "Cancel anytime • Crypto payments accepted"
      },
      exclusiveNote: "[🔒] Access granted only to members of the Inner Circle",
      tips: [
        "[💡] Did you know? Long-term plans save you up to 17%",
        "[🔧] Use our tools to deploy Ritual and earn rewards",
        "[🛡️] Joining Private Access unlocks 100+ tested scripts"
      ]
    },
    interface: {
      copyButton: {
        default: "[>] Копировать команду",
        copied: "[✓] Скопировано"
      },
      terminal: {
        title: "Команда установки",
        cursor: "_"
      },
      preview: {
        scriptTitle: "> Пример скрипта",
        scriptDescription: "Настройка ноды Ritual проста с нашим скриптом. Просто запустите команду ниже и следуйте инструкциям на экране.",
        scriptCommand: "bash <(curl -s https://scripts.xnod3.com/nodes/ritual-wizzard.sh)",
        supportTitle: "> Экспертная техническая поддержка",
        supportDescription: "Получите персональную поддержку по настройке и устранению неполадок нод напрямую от наших экспертов.",
        communityTitle: "> Сообщество и обновления",
        communityDescription: "Получайте первыми доступ к новым скриптам, обновлениям и инсайтам от команды xNod3 и закрытого сообщества.",
        communitySubDescription: "Присоединяйтесь к сети энтузиастов блокчейна и экспертов отрасли, будьте впереди с эксклюзивным контентом и делайте работу ваших нод эффективнее с помощью инсайдерских знаний."
      }
    }
  }
};
