import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/common/TerminalWindow.css';
import '../styles/NotFoundPage.css';

function NotFoundPage() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          navigate('/');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="terminal-container">
      <div className="terminal-window error-terminal">
        <div className="terminal-header">
          <div className="terminal-controls">
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
          </div>
          <div className="terminal-title">system_error.log</div>
        </div>
        
        <div className="terminal-content error-content">
          <div className="error-code">[404]</div>

          <div className="error-details">
            <div className="error-line">
              <span className="error-marker">[*]</span> 
              The requested resource could not be located on the server
            </div>
            
            <div className="error-section">
              <div className="error-line">
                <span className="error-marker">[?]</span> 
                Possible causes:
              </div>
              <ul className="error-list">
                <li><span className="error-marker">></span> Page has been moved or deleted</li>
                <li><span className="error-marker">></span> URL contains a typo</li>
                <li><span className="error-marker">></span> Access denied to requested resource</li>
              </ul>
            </div>
          </div>

          <div className="system-response">
            <div className="error-line">
              <span className="error-marker">[~]</span> 
              System response: Initiating automatic redirect...
            </div>
            <div className="countdown-line">
              <span className="error-marker">[{countdown}]</span> 
              Redirecting to home page_
            </div>
          </div>

          <div className="error-actions">
            <button onClick={() => navigate('/')} className="terminal-button">
              [↵] Return to Homepage
            </button>
            <button onClick={() => navigate(-1)} className="terminal-button">
              [←] Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
