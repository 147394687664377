import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import api from '../services/api';
import ImageLibrary from '../components/guides/GuideEditor/ImageLibrary';
import '../styles/WikiEditor.css';

function WikiEditorPage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(slug);
  const fileInputRef = useRef(null);
  
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    description: '',
    content: '',
    category_id: null,
    headerImage: '',
    status: 'published'
  });

  const [loading, setLoading] = useState(isEditing);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [libraryImages, setLibraryImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);

  useEffect(() => {
    fetchCategories();
    if (isEditing) {
      fetchArticle();
    }
  }, [slug]);

  // Обработка вставки изображений через Ctrl+V
  useEffect(() => {
    const handlePaste = async (e) => {
      const items = e.clipboardData?.items;
      if (!items) return;

      const imageItem = Array.from(items).find(item => item.type.startsWith('image'));
      if (imageItem) {
        e.preventDefault();
        const file = imageItem.getAsFile();
        try {
          const formData = new FormData();
          formData.append('image', file);
          
          const response = await api.post('/images/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          const imageMarkdown = `![${response.data.public_id}](${response.data.url})`;
          
          const textarea = e.target;
          const cursorPos = textarea.selectionStart;
          const textBefore = textarea.value.substring(0, cursorPos);
          const textAfter = textarea.value.substring(cursorPos);
          
          setFormData(prev => ({
            ...prev,
            content: textBefore + imageMarkdown + textAfter
          }));

        } catch (err) {
          console.error('Error uploading image:', err);
        }
      }
    };

    const contentTextarea = document.querySelector('.wiki-content-editor textarea');
    if (contentTextarea) {
      contentTextarea.addEventListener('paste', handlePaste);
      return () => contentTextarea.removeEventListener('paste', handlePaste);
    }
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.get('/wiki/categories');
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchArticle = async () => {
    try {
      const response = await api.get(`/wiki/articles/${slug}`);
      setFormData({
        ...response.data,
        category_id: response.data.category_id ? parseInt(response.data.category_id, 10) : null
      });
    } catch (err) {
      setError('Ошибка при загрузке статьи');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await api.put(`/wiki/articles/${slug}`, formData);
      } else {
        await api.post('/wiki/articles', formData);
      }
      navigate('/admin/wiki');
    } catch (err) {
      setError(err.response?.data?.error || 'Ошибка при сохранении');
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      const response = await api.post('/upload', formData);
      return response.data.url;
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const handleInsertLibraryImage = (imageUrl) => {
    setFormData(prev => ({
      ...prev,
      content: prev.content + `![](${imageUrl})`
    }));
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setFormData(prev => ({
      ...prev,
      category_id: value ? parseInt(value, 10) : null
    }));
  };

  if (loading) return <div className="wiki-editor-loading">Загрузка...</div>;
  if (error) return <div className="wiki-editor-error">{error}</div>;

  return (
    <div className="wiki-editor-page">
      <Helmet>
        <title>{isEditing ? 'Редактирование Wiki' : 'Создание Wiki'} - xNod3</title>
      </Helmet>

      <form onSubmit={handleSubmit} className="wiki-editor-form">
        <div className="wiki-metadata">
          <div className="form-group">
            <input
              type="text"
              placeholder="Заголовок статьи"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
              className="title-input"
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="URL статьи (slug)"
              value={formData.slug}
              onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
              required
              className="slug-input"
            />
          </div>

          <div className="form-group">
            <select
              value={formData.category_id || ''}
              onChange={handleCategoryChange}
              className="category-select"
            >
              <option value="">Выберите категорию</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <textarea
              placeholder="Краткое описание"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              className="description-input"
            />
          </div>

          <div className="image-controls">
            <button 
              type="button" 
              onClick={() => fileInputRef.current?.click()}
              className="upload-btn"
            >
              [📷] Upload Image
            </button>
            <button 
              type="button"
              onClick={() => setShowImageLibrary(true)}
              className="library-btn"
            >
              [🖼] Image Library
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <div className="form-options">
          <div className="form-group">
            <label>[>] Статус_</label>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              className="status-select"
            >
              <option value="draft">Черновик</option>
              <option value="published">Опубликовано</option>
            </select>
          </div>
        </div>

        <div className="wiki-content-editor">
          <textarea
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            placeholder="Содержание статьи (поддерживается Markdown)"
            required
            className="content-input"
          />
        </div>

        <div className="wiki-editor-actions">
          <button 
            type="button" 
            onClick={() => navigate('/admin/wiki')} 
            className="cancel-btn"
          >
            [←] Отмена
          </button>
          <button type="submit" className="save-btn">
            [✓] {isEditing ? 'Сохранить' : 'Создать'}
          </button>
        </div>
      </form>

      <div className="wiki-editor-preview">
        <h2 className="preview-header">[👁] Предпросмотр</h2>
        <div className="preview-content">
          <ReactMarkdown>{formData.content}</ReactMarkdown>
        </div>
      </div>

      {showImageLibrary && (
        <ImageLibrary 
          images={libraryImages}
          loading={loadingImages}
          onSelect={handleInsertLibraryImage}
          onClose={() => setShowImageLibrary(false)}
        />
      )}
    </div>
  );
}

export default WikiEditorPage;
