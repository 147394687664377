import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

const GuideMetadata = ({ formData, onChange }) => {
  const [tagInput, setTagInput] = useState('');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await api.get('/projects');
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };
    fetchProjects();
  }, []);

  const handleAddTag = () => {
    if (tagInput.trim()) {
      if (!formData.tags?.includes(tagInput.trim())) {
        onChange({
          ...formData,
          tags: [...(formData.tags || []), tagInput.trim()]
        });
        setTagInput('');
      } else {
        console.warn('Тег уже существует');
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    onChange({
      ...formData,
      tags: formData.tags.filter(tag => tag !== tagToRemove)
    });
  };

  return (
    <div className="guide-metadata">
      <div className="form-group">
        <label>[>] Title_</label>
        <input
          type="text"
          value={formData.title}
          onChange={(e) => onChange({ ...formData, title: e.target.value })}
          placeholder="Название гайда..."
          required
        />
      </div>

      <div className="form-group">
        <label>[>] Project_</label>
        <select
          value={formData.project_id || ''}
          onChange={(e) => onChange({ ...formData, project_id: e.target.value })}
        >
          <option value="">Select project...</option>
          {projects.map(project => (
            <option key={project.id} value={project.id}>
              {project.name} ({project.node_version || 'No version'})
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>[>] Slug_</label>
        <input
          type="text"
          value={formData.slug}
          onChange={(e) => onChange({ ...formData, slug: e.target.value })}
          placeholder="URL-friendly название..."
        />
      </div>

      <div className="form-group">
        <label>[>] Category_</label>
        <select
          value={formData.category}
          onChange={(e) => onChange({ ...formData, category: e.target.value })}
        >
          <option value="nodes">Nodes</option>
          <option value="tools">Tools</option>
          <option value="helpful">Helpful</option>
        </select>
      </div>

      <div className="form-group">
        <label>[>] Description_</label>
        <textarea
          value={formData.description}
          onChange={(e) => onChange({ ...formData, description: e.target.value })}
          placeholder="Краткое описание..."
        />
      </div>

      <div className="tags-section">
        <div className="tags-input">
          <input
            type="text"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            placeholder="Добавить тег..."
            onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
          />
          <button type="button" onClick={handleAddTag}>[+] Добавить</button>
        </div>
        <div className="tags-list">
          {formData.tags?.map(tag => (
            <span key={tag} className="tag">
              {tag}
              <button onClick={() => handleRemoveTag(tag)}>[×]</button>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuideMetadata;