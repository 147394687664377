// src/pages/Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthTerminal from './AuthTerminal';
import '../styles/common/TerminalWindow.css';
import './Dashboard.css';
import api from '../services/api';
import PaymentModal from '../components/PaymentModal';
import TelegramAccess from '../components/TelegramAccess';
import { Helmet } from 'react-helmet-async';

// Константы уровней доступа
const ACCESS_LEVELS = {
  INIT_0x: {
    name: 'INIT_0x',
    description: 'Basic access level'
  },
  CIPHER_1x: {
    name: 'CIPHER_1x',
    description: 'Advanced access level'
  },
  KERNEL_2x: {
    name: 'KERNEL_2x',
    description: 'Premium access level'
  },
  ROOT_3x: {
    name: 'ROOT_3x',
    description: 'Maximum access level'
  },
  PHANTOM_X: {
    name: 'PHANTOM_X',
    description: 'Enterprise access level'
  }
};

// Моковые данные для демонстрации (можно заменить на реальные или убрать, если загружаете из API)
const GUIDES_DATA = [
  { 
    id: 1, 
    title: "Ritual Node Deployment Guide", 
    category: "deployment",
    status: "new" 
  },
  { 
    id: 2, 
    title: "Celestia Node Setup Tutorial", 
    category: "setup",
    status: "updated" 
  },
  { 
    id: 3, 
    title: "Node Security Best Practices", 
    category: "security",
    status: "standard" 
  }
];

const NEWS_DATA = [
  {
    id: 1,
    date: "2024-01-15",
    title: "New Celestia Integration Released",
    content: "Full guide available in the Guides section"
  },
  {
    id: 2,
    date: "2024-01-10",
    title: "System Maintenance Scheduled",
    content: "Scheduled for January 20th, 2024"
  }
];

const ENTERPRISE_FEATURES = [
  {
    id: 1,
    title: "Dedicated Infrastructure",
    description: "Enterprise-grade node hosting with 99.9% uptime",
    status: "active"
  },
  {
    id: 2,
    title: "24/7 Priority Support",
    description: "Direct access to senior technical team",
    status: "active"
  },
  {
    id: 3,
    title: "Custom Solutions",
    description: "Tailored node deployment strategies",
    status: "available"
  }
];

const AVAILABLE_COMMANDS = [
  { command: "VIEW_NODES", description: "Display all active nodes" },
  { command: "CHECK_STATUS", description: "Check system status" },
  { command: "REQUEST_ACCESS", description: "Request elevated access" },
  { command: "CONTACT_SUPPORT", description: "Open support ticket" }
];

function Dashboard() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState('private');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [paymentURL, setPaymentURL] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [currentUsername, setCurrentUsername] = useState('');

  const menuRef = useRef(null);
  const userContainerRef = useRef(null);

  const fetchSubscription = async (token) => {
    try {
      const response = await api.get('/payments', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Subscription response:', response.data);
      setSubscription(response.data.subscription);
    } catch (error) {
      console.error('Error fetching subscription:', error);
      setError('Failed to fetch subscription status');
    }
  };

  const handleDisconnect = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setCurrentUsername('');
    setSubscription(null);
    setShowUserMenu(false);
  };

  // Эффект для обработки кликов вне меню
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userContainerRef.current && 
          !userContainerRef.current.contains(event.target) &&
          menuRef.current && 
          !menuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Эффект для проверки аутентификации
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const response = await api.get('/auth/me');
        if (response.data.user) {
          setIsAuthenticated(true);
          setCurrentUsername(response.data.user.telegram_username);
          await fetchSubscription(token);
        } else {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  const handleMouseEnter = () => {
    setShowUserMenu(true);
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    if (!menuRef.current?.contains(relatedTarget) && 
        !userContainerRef.current?.contains(relatedTarget)) {
      setShowUserMenu(false);
    }
  };

  const handleTabChange = (tab) => {
    setLoading(true);
    setActiveTab(tab);
    setTimeout(() => setLoading(false), 300); // Имитация згрузки
  };

  const handleSubscriptionManage = () => {
    setActiveModal('subscription');
  };

  const handleGuideOpen = (guideId) => {
    // Проверк доступа к гайду
    if (!subscription || subscription.status !== 'active') {
      setError('Active subscription required');
      return;
    }
    setActiveModal(`guide-${guideId}`);
  };

  const handleEnterpriseRequest = async () => {
    setLoading(true);
    try {
      // Здесь будет API запрос для запроса Enterprise достпа
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubscription(prev => ({
        ...prev,
        type: 'PHANTOM_X',
        status: 'active',
        expiresAt: '2025-12-31',
        features: ['guides', 'news', 'priority-support', 'custom-solutions']
      }));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Компонент модального окна
  const Modal = ({ children, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
        <button className="modal-close" onClick={onClose}>[x]</button>
      </div>
    </div>
  );

// Обработчик создания платежа
const handlePay = async (months) => {
  try {
    setLoading(true);
    setError(null);
    
    const response = await api.post(
      '/payments/create',
      { months },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data?.payment?.url) {
      window.location.href = response.data.payment.url;
    } else {
      setError('Ошибка при создании платежа');
    }
  } catch (err) {
    console.error('Ошибка оплаты:', err);
    setError(err.response?.data?.message || 'Ошибка при создании платежа');
  } finally {
    setLoading(false);
  }
};

const handleSubscribe = () => {
  handlePay(); // Используем существующую функцию для создания платежа
};

const handlePaymentSubmit = async (months) => {
  try {
    setLoading(true);
    setError(null);
    setShowPaymentModal(false);
    
    const response = await api.post(
      '/payments/create',
      { months },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data?.payment?.url) {
      window.location.href = response.data.payment.url;
    } else {
      setError('Ошибка при создании платежа');
    }
  } catch (err) {
    console.error('Ошибка оплаты:', err);
    setError(err.response?.data?.message || 'Ошибка при создании платежа');
  } finally {
    setLoading(false);
  }
};

  // Удаляем компонент формы доступа и сразу переходим к проверке аутентификации
  if (!isAuthenticated) {
    return <AuthTerminal onAuthenticated={(username) => {
      setIsAuthenticated(true);
      setCurrentUsername(username);
      fetchSubscription(localStorage.getItem('token'));
    }} />;
  }

  const renderUserPanel = () => (
    <div className="xnode-terminal-header">
      <div className="xnode-terminal-title">
        xNod3_DASHBOARD [V1.0]
      </div>
      <div className="xnode-user-info">
        <span className="xnode-user-label">[USER]</span>
        <span className="xnode-username">{currentUsername || 'Anonymous'}</span>
      </div>
      <button 
        onClick={handleDisconnect} 
        className="xnode-disconnect-button"
      >
        [×] DISCONNECT
      </button>
    </div>
  );

  return (
    <>
    <Helmet>
        <title>Dashboard - xNod3</title>
        <meta name="description" content="Manage your nodes and subscriptions in the xNod3 dashboard" />
      </Helmet>
    <div className="dashboard-container">
      <div className="terminal-window">
        <div className="terminal-header">
          <div className="terminal-controls">
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
            <div className="terminal-control"></div>
          </div>
          
          {renderUserPanel()}
        </div>

        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'private' ? 'active' : ''}`}
            onClick={() => handleTabChange('private')}>
            Private Access [>]
          </button>
          <button 
            className={`tab ${activeTab === 'enterprise' ? 'active' : ''}`}
            onClick={() => handleTabChange('enterprise')}>
            Enterprise Solutions [>]
          </button>
        </div>

        <div className="terminal-content">
          {loading ? (
            <div className="loading-indicator">
              <span className="blink">[Loading system data...]</span>
              <div className="loading-details">
                Checking subscription status...
              </div>
            </div>
          ) : error ? (
            <div className="error-container">
              {typeof error === 'string' ? (
                <div className="error-message" onClick={() => setError(null)}>
                  <span className="error-icon">[!]</span>
                  <span className="error-text">{error}</span>
                </div>
              ) : (
                error // Рендерим JSX компонент auth-error если error не строка
              )}
            </div>
          ) : (
            <>
              <div className="status-blocks-container">
                <div className="status-block system">
                  <div className="block-header">
                    <h3 className="block-title">[>] System Status<span className="cursor">_</span></h3>
                  </div>
                  <div className="status-content">
                    <div className="status-row">
                      <span className="status-icon online">[✓]</span>
                      <span className="status-label">Status:</span>
                      <span className="status-value">ONLINE</span>
                    </div>
                    
                    <div className="status-row">
                      <span className="status-icon level">[>]</span>
                      <span className="status-label">Access Level:</span>
                      <span className="status-value level">
                        {subscription?.type && (
                          <span className="level-badge">
                            {ACCESS_LEVELS[subscription.type]?.name || subscription.type}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="status-block subscription">
                  <div className="block-header">
                    <h3 className="block-title">[>] Subscription Status<span className="cursor">_</span></h3>
                  </div>
                  <div className="status-content">
                    <div className="status-row">
                      <span className="status-icon">
                        {subscription?.status === 'active' ? '[✓]' : '[!]'}
                      </span>
                      <span className="status-label">Статус:</span>
                      <span className="status-value">
                        {subscription?.status === 'active' ? 'АКТИВНА' : 'НЕТ АКТИВНОЙ ПОДПИСКИ'}
                      </span>
                    </div>
                    
                    {subscription?.status === 'active' && (
                      <>
                        <div className="status-row">
                          <span className="status-icon time">[⏳]</span>
                          <span className="status-label">Действует до:</span>
                          <span className="status-value">
                            {subscription.expiresAtFormatted || 'Не указано'}
                          </span>
                        </div>
                        
                        <div className="status-row">
                          <span className="status-icon features">[⚡]</span>
                          <span className="status-label">Функции:</span>
                          <div className="features-list">
                            {subscription.features.map((feature, index) => (
                              <span key={index} className="feature-badge">
                                [{feature}]
                              </span>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    
                    {subscription?.status !== 'active' && (
                      <div className="status-row subscription-button-row">
                        <button 
                          className="xnode-subscribe-button"
                          onClick={() => setShowPaymentModal(true)}
                          disabled={loading}
                        >
                          <div className="xnode-subscribe-content">
                            <span className="xnode-subscribe-icon">[₿]</span>
                            <span className="xnode-subscribe-text">
                              {loading ? '[ PROCESSING... ]' : '[ SUBSCRIBE_NOW ]'}
                            </span>
                            <span className="xnode-subscribe-arrow">→</span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="status-block telegram">
                  <TelegramAccess subscription={subscription} />
                </div>
              </div>

              {activeTab === 'enterprise' && (
          <div className="maintenance-notice">
            <div className="notice-header">
              <span className="notice-icon">[⚠]</span>
              <h3 className="notice-title">RESTRICTED ACCESS<span className="blink">_</span></h3>
            </div>
            
            <div className="notice-content">
              <div className="notice-message">
                <p className="primary-message">
                  [SYSTEM]: Enterprise infrastructure is in private testing phase
                </p>
                <p className="details-message">
                  Access to enterprise-grade node deployment and management solutions is currently available only to verified xNod3 infrastructure partners with proven track record in blockchain node operations
                </p>
              </div>

              <div className="status-block encrypted">
                <div className="status-row">
                  <span className="status-icon lock">[⟁]</span>
                  <span className="status-label">Status:</span>
                  <span className="status-value restricted">PRIVATE_TESTING</span>
                </div>
                <div className="status-row">
                  <span className="status-icon time">[⏳]</span>
                  <span className="status-label">Public Release:</span>
                  <span className="status-value">Q1 2025</span>
                </div>
              </div>

              <div className="notice-footer">
                <p className="crypto-message">[>] Join xNod3 Enterprise. Scale your node infrastructure with professional solutions.</p>
                <button className="request-access-button">
                  <span className="button-icon">[⚡]</span>
                  <span className="button-text">REQUEST_ACCESS</span>
                  <span className="button-arrow">[→]</span>
                </button>
              </div>
            </div>
                </div>
              )}

              <div className="navigation-grid">
                <Link to="/guides" className="nav-card">
                  <div className="nav-card-content">
                    <div className="nav-card-icon">[📚]</div>
                    <h3 className="nav-card-title">Guides</h3>
                    <p className="nav-card-description">Access deployment and setup tutorials</p>
                    <div className="nav-card-arrow">[→]</div>
                  </div>
                  <div className="nav-card-scan-line"></div>
                </Link>

                <Link to="/news" className="nav-card">
                  <div className="nav-card-content">
                    <div className="nav-card-icon">[📰]</div>
                    <h3 className="nav-card-title">News</h3>
                    <p className="nav-card-description">Latest updates and announcements</p>
                    <div className="nav-card-arrow">[→]</div>
                  </div>
                  <div className="nav-card-scan-line"></div>
                </Link>
              </div>
            </>
          )}
        </div>

       
      </div>

      {/* Модальные окна */}
      {activeModal && (
        <Modal onClose={() => setActiveModal(null)}>
          {activeModal === 'subscription' ? (
            <div className="subscription-modal">
              <h3>Manage Subscription</h3>
              {/* Содержимое модального окна подписки */}
              <p>Here you can manage your subscription settings.</p>
              {/* Можно добавить функционал обновления подписки */}
            </div>
          ) : activeModal.startsWith('guide-') ? (
            <div className="guide-modal">
              <h3>Guide Content</h3>
              {/* Содержимое гайда */}
              <p>Detailed guide content goes here.</p>
            </div>
          ) : null}
        </Modal>
      )}

      {/* Ссылка на опату после создания платежа */}
      {paymentURL && (
        <Modal onClose={() => setPaymentURL('')}>
          <div className="payment-modal">
            <h3>Proceed to Payment</h3>
            <p>Click the button below to complete your payment.</p>
            <a href={paymentURL} target="_blank" rel="noopener noreferrer" className="payment-button">
              Go to Payment Page [↗]
            </a>
          </div>
        </Modal>
      )}

      {/* Сообщение об ошибке */}
      {error && (
        <div className="error-container">
          {typeof error === 'string' ? (
            <div className="error-message" onClick={() => setError(null)}>
              <span className="error-icon">[!]</span>
              <span className="error-text">{error}</span>
            </div>
          ) : (
            error // Рендеим JSX компонент auth-error если error не стрка
          )}
        </div>
      )}

      {showPaymentModal && (
        <Modal onClose={() => setShowPaymentModal(false)}>
          <PaymentModal 
            onClose={() => setShowPaymentModal(false)}
            onSubmit={handlePaymentSubmit}
          />
        </Modal>
      )}
    </div>
    </>
  );
}

export default Dashboard;
