import React, { useEffect, useRef } from 'react';

const NetworkBackground = () => {
  const canvasRef = useRef(null);

  class Node {
    constructor(canvas) {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d');
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.size = 2 + Math.random() * 3;
      this.speedX = (Math.random() - 0.5) * 2;
      this.speedY = (Math.random() - 0.5) * 2;
      this.connections = [];
      this.rewards = [];
      this.energy = 100;
      this.isCollecting = false;
      this.collectionProgress = 0;
      this.state = 'searching';
      this.connectedNodes = new Set();
      this.pulseSize = 0;
      this.pulseOpacity = 1;
      this.miningPower = Math.random() * 0.5 + 0.5;
      this.lastRewardTime = 0;
      this.cluster = null;
      this.totalMined = 0;
      this.blocksMined = 0;
      this.efficiency = Math.random() * 0.5 + 0.5;
      this.networkShare = 0;
      this.lastBlockTime = Date.now();
      this.blockReward = 100;
      this.difficulty = 1;
    }

    update(nodes, time) {
      this.updateState(nodes, time);
      this.updatePosition();
      this.updateConnections();
      this.updateRewards();
      this.updateEnergy();
    }

    updateState(nodes, time) {
      switch(this.state) {
        case 'searching':
          if (this.findNearbyNodes(nodes).length >= 2) {
            this.state = 'connecting';
            this.pulseSize = this.size;
          }
          break;

        case 'connecting':
          if (this.connectedNodes.size >= 3) {
            this.state = 'mining';
            this.isCollecting = true;
          }
          break;

        case 'mining':
          if (time - this.lastRewardTime > 5000 * (1 / this.miningPower)) {
            this.createReward();
            this.lastRewardTime = time;
            this.energy -= 10;
            
            if (Math.random() < 0.1) {
              this.state = 'searching';
              this.connectedNodes.clear();
            }
          }
          break;

        case 'transferring':
          this.transferEnergy();
          break;
      }
    }

    updatePosition() {
      this.x += this.speedX;
      this.y += this.speedY;

      const margin = 50;
      if (this.x < margin) this.speedX += 0.1;
      if (this.x > this.canvas.width - margin) this.speedX -= 0.1;
      if (this.y < margin) this.speedY += 0.1;
      if (this.y > this.canvas.height - margin) this.speedY -= 0.1;

      const maxSpeed = 3;
      const speed = Math.hypot(this.speedX, this.speedY);
      if (speed > maxSpeed) {
        this.speedX = (this.speedX / speed) * maxSpeed;
        this.speedY = (this.speedY / speed) * maxSpeed;
      }

      if (Math.random() < 0.02) {
        this.speedX += (Math.random() - 0.5) * 0.5;
        this.speedY += (Math.random() - 0.5) * 0.5;
      }
    }

    findNearbyNodes(nodes) {
      return nodes.filter(node => {
        if (node === this) return false;
        const distance = Math.hypot(node.x - this.x, node.y - this.y);
        return distance < 150;
      });
    }

    transferEnergy() {
      this.connectedNodes.forEach(node => {
        if (this.energy > node.energy + 20) {
          this.energy -= 5;
          node.energy += 4;
        }
      });
    }

    createReward() {
      const baseReward = this.blockReward;
      const networkBonus = this.connectedNodes.size * 0.2 + 1;
      const efficiencyBonus = this.efficiency;
      const finalReward = Math.floor(baseReward * networkBonus * efficiencyBonus / this.difficulty);
      
      this.totalMined += finalReward;
      this.blocksMined++;
      this.lastBlockTime = Date.now();

      this.rewards.push({
        x: this.x,
        y: this.y,
        speed: 1 + Math.random(),
        opacity: 1,
        value: finalReward,
        color: this.getRewardColor(finalReward),
        type: 'block'
      });

      window.nodeStats = window.nodeStats || {
        totalMined: 0,
        totalBlocks: 0,
        activeNodes: 0,
        networkHashrate: 0,
        avgBlockTime: 0,
        lastBlockTimes: [],
      };
      
      window.nodeStats.totalMined += finalReward;
      window.nodeStats.totalBlocks++;
      window.nodeStats.lastBlockTimes.push(Date.now());
      if (window.nodeStats.lastBlockTimes.length > 10) {
        window.nodeStats.lastBlockTimes.shift();
      }
    }

    getRewardColor(value) {
      if (value > 150) return '#00ff00';
      if (value > 100) return '#88ff88';
      return '#4a4';
    }

    draw() {
      this.drawConnections();
      
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      this.ctx.fillStyle = this.getNodeColor();
      this.ctx.fill();

      if (this.state === 'mining') {
        this.drawPulse();
      }

      this.drawRewards();

      this.drawEnergyIndicator();
    }

    drawConnections() {
      this.connectedNodes.forEach(node => {
        const distance = Math.hypot(node.x - this.x, node.y - this.y);
        const opacity = 1 - distance / 150;
        
        this.ctx.beginPath();
        this.ctx.moveTo(this.x, this.y);
        this.ctx.lineTo(node.x, node.y);
        
        const gradient = this.ctx.createLinearGradient(
          this.x, this.y, node.x, node.y
        );
        gradient.addColorStop(0, `rgba(0, 255, 0, ${opacity})`);
        gradient.addColorStop(1, `rgba(0, 255, 0, ${opacity * 0.5})`);
        
        this.ctx.strokeStyle = gradient;
        this.ctx.lineWidth = 0.5;
        this.ctx.stroke();

        if (this.state === 'transferring') {
          this.drawEnergyParticles(node);
        }
      });
    }

    drawPulse() {
      this.pulseSize += 0.2;
      this.pulseOpacity -= 0.02;

      if (this.pulseOpacity <= 0) {
        this.pulseSize = this.size;
        this.pulseOpacity = 1;
      }

      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.pulseSize, 0, Math.PI * 2);
      this.ctx.strokeStyle = `rgba(0, 255, 0, ${this.pulseOpacity})`;
      this.ctx.stroke();
    }

    getNodeColor() {
      switch(this.state) {
        case 'mining': return '#00ff00';
        case 'connecting': return '#88ff88';
        case 'transferring': return '#44ff44';
        default: return '#226622';
      }
    }

    drawEnergyParticles(targetNode) {
      const particleCount = 3;
      for (let i = 0; i < particleCount; i++) {
        const progress = (Date.now() % 1000) / 1000 + i / particleCount;
        const x = this.x + (targetNode.x - this.x) * progress;
        const y = this.y + (targetNode.y - this.y) * progress;
        
        this.ctx.beginPath();
        this.ctx.arc(x, y, 1, 0, Math.PI * 2);
        this.ctx.fillStyle = '#00ff00';
        this.ctx.fill();
      }
    }

    updateConnections() {
      this.connections.forEach(node => {
        const distance = Math.hypot(node.x - this.x, node.y - this.y);
        if (distance < 150 && !this.connectedNodes.has(node)) {
          if (this.state === 'connecting' && this.connectedNodes.size < 3) {
            this.connectedNodes.add(node);
          }
        } else if (distance > 150 && this.connectedNodes.has(node)) {
          this.connectedNodes.delete(node);
        }
      });
    }

    updateRewards() {
      this.rewards = this.rewards.filter(reward => {
        reward.y += reward.speed;
        reward.opacity -= 0.02;
        return reward.opacity > 0;
      });
    }

    updateEnergy() {
      if (this.energy < 20) {
        this.state = 'searching';
        this.connectedNodes.clear();
      }

      if (this.energy < 100 && this.state !== 'mining') {
        this.energy += 0.1;
      }

      if (this.connectedNodes.size > 0) {
        this.energy -= 0.05 * this.connectedNodes.size;
      }
    }

    drawRewards() {
      this.rewards.forEach(reward => {
        this.ctx.fillStyle = `rgba(0, 255, 0, ${reward.opacity})`;
        this.ctx.font = '12px Anonymous Pro';
        this.ctx.fillText(`+${reward.value}`, reward.x, reward.y);
      });
    }

    drawEnergyIndicator() {
      const energyRadius = this.size + 3;
      const energyPercentage = this.energy / 100;
      
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, energyRadius, 
        -Math.PI / 2, 
        -Math.PI / 2 + (2 * Math.PI * energyPercentage)
      );
      this.ctx.strokeStyle = `rgba(0, 255, 0, ${energyPercentage * 0.5})`;
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let nodes = [];

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const init = () => {
      handleResize();
      nodes = Array(30).fill().map(() => new Node(canvas));
      
      nodes.forEach(node => {
        node.connections = nodes.filter(n => n !== node);
      });
    };

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      nodes.forEach(node => {
        node.update(nodes, Date.now());
        node.draw();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    init();
    animate();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        opacity: 0.3,
        pointerEvents: 'none'
      }}
    />
  );
};

export default NetworkBackground;
