import React from 'react';

const ImageControls = ({
  headerImage,
  onHeaderImageChange,
  onHeaderImageUpload,
  onLibraryOpen,
  onSectionImageUpload,
  uploadError
}) => {
  return (
    <div className="image-controls">
      <div className="form-group">
        <label>[🖼] Header Image</label>
        <div className="header-image-controls">
          <input
            type="text"
            value={headerImage || ''}
            onChange={(e) => onHeaderImageChange(e.target.value)}
            placeholder="URL изображения..."
          />
          <div className="image-upload-buttons">
            <label className="upload-button">
              [+] Upload
              <input
                type="file"
                onChange={onHeaderImageUpload}
                accept="image/*"
                style={{ display: 'none' }}
              />
            </label>
            <button
              type="button"
              onClick={onLibraryOpen}
              className="library-button"
            >
              [📚] Library
            </button>
          </div>
        </div>
        {headerImage && (
          <img 
            src={headerImage} 
            alt="Header preview" 
            className="header-image-preview" 
          />
        )}
      </div>

      <div className="form-group">
        <label>[📎] Upload Image for Sections</label>
        <div className="section-image-upload">
          <input
            type="file"
            onChange={onSectionImageUpload}
            accept="image/*"
          />
          {uploadError && <div className="error">{uploadError}</div>}
        </div>
      </div>
    </div>
  );
};

export default ImageControls;
