import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const LanguageToggle = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <button 
      className="language-toggle"
      onClick={() => setLanguage(language === 'en' ? 'ru' : 'en')}
    >
      {language === 'en' ? 'RU' : 'EN'}
    </button>
  );
};

export default LanguageToggle;
