import { useState, useEffect } from 'react';
import api from '../services/api';

export const useSubscriptionPrice = () => {
  const [price, setPrice] = useState({ amount: '27', currency: 'USDT' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await api.get('/payments/price');
        setPrice(response.data);
      } catch (err) {
        console.error('Ошибка получения цены:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPrice();
  }, []);

  return { price, loading, error };
}; 